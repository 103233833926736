import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Dictionary } from '../../../utils/dictionary';

export interface LocationState {
    modalLocationStates: Dictionary<boolean>;
}

export function useCloseOnBack(
    closeOnBack: boolean,
    isOpen: boolean,
    modalKey: string,
    onClose: () => void
) {
    const location = useLocation<LocationState | undefined>();
    const history = useHistory<LocationState | undefined>();

    //Use effect to add or remove event listener if the popup is open or closed
    useEffect(() => {
        if (closeOnBack && location.state?.modalLocationStates) {
            const modalPopupOpen =
                location.state?.modalLocationStates[modalKey];

            if (modalPopupOpen) {
                window.addEventListener('popstate', onClose);
            } else if (!modalPopupOpen) {
                window.removeEventListener('popstate', onClose);
            }
        }
    }, [closeOnBack, onClose, location.state?.modalLocationStates, modalKey]);

    //Use effect to push or pop the history depending on if the popupl is open or closed
    useEffect(() => {
        var locationState: LocationState;
        if (closeOnBack) {
            if (!location.state?.modalLocationStates) {
                locationState = { modalLocationStates: {} };
            } else {
                locationState = location.state;
            }

            if (locationState.modalLocationStates[modalKey] === undefined) {
                locationState.modalLocationStates[modalKey] = false;
            }

            const locationModalPopupOpen =
                locationState.modalLocationStates[modalKey];

            //Check on the location to make sure we don't double push or pop the history
            if (isOpen && !locationModalPopupOpen) {
                locationState.modalLocationStates[modalKey] = true;
                history.push(location.pathname, locationState);
            } else if (!isOpen && locationModalPopupOpen) {
                history.goBack();
            }
        }
    }, [
        isOpen,
        closeOnBack,
        history,
        location.state?.modalLocationStates,
        location.pathname,
        modalKey,
        location.state,
    ]);
}

export default useCloseOnBack;
