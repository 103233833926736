import { MenuLink } from '../../../../MenuLink';

import { Link } from 'react-router-dom';
import classNames from 'classnames';
import SubMenuLink from './SubMenuLink/SubMenuLink';
import styles from './SubMenu.module.scss';
import { useDesktopMenu } from '../../DesktopMenuContext';

export interface SubMenuProps {
    link: MenuLink;
    className?: string;
}

function SubMenu({ link, className }: SubMenuProps) {
    const { closeMenu } = useDesktopMenu();
    return (
        <div className={classNames(styles.subMenu, className)}>
            {link.subLinks.length > 0 && (
                <div className={styles.content}>
                    <h3>{link.title}</h3>
                    <ul className={styles.links}>
                        {link.subLinks.map(({ title, url }, index) => (
                            <SubMenuLink key={index} title={title} url={url} />
                        ))}
                    </ul>
                    <Link
                        to={link.url}
                        className={styles.viewAllLink}
                        onClick={handleViewAllClick}
                    >
                        View all {link.title} destinations
                    </Link>
                </div>
            )}
            {!!link?.imageUrl && (
                <picture>
                    <img src={buildImageSrc(link.imageUrl, 280, 360)} alt="" />
                </picture>
            )}
        </div>
    );

    function handleViewAllClick() {
        closeMenu();
    }
}

function buildImageSrc(url: string, width: number, height: number) {
    return `${url}?w=${width}&h=${height}&fit=crop&auto=format`;
}

export default SubMenu;
