import { TourDeparture } from '@/endurance/tours';
import { RequestTourDeparturesAction } from '@/redux/actions/tour/RequestTourDeparturesAction';

import { call, put } from 'redux-saga/effects';
import { getTourDepartures } from '../../apis/tourApi.v2';
import {
    requestTourDeparturesFailure,
    requestTourDeparturesSuccess,
} from '../actions/tourDepartures.actions';
import { logError, logErrorWithInfo } from '../../utils/sentry';

export function* requestTourDepartures(action: RequestTourDeparturesAction) {
    try {
        const result: {
            success: boolean;
            errors: string[];
            departures: TourDeparture[] | null;
        } = yield call(getTourDepartures, action.tourId, action.currencyCode);
        if (result.success && !!result.departures) {
            yield put(
                requestTourDeparturesSuccess(result.departures, action.tourId)
            );
        } else {
            yield put(
                requestTourDeparturesFailure(action.tourId, result.errors)
            );
            logErrorWithInfo(Error('Failed to fetch tour departures'), {
                tourId: action.tourId,
                result: result,
            });
        }
    } catch (e) {
        logError(e);
        yield put(requestTourDeparturesFailure(action.tourId, [`${e}`]));
    }
}
