import classNames from 'classnames';
import Modal, { ModalProps } from '../Modal/Modal';
import styles from './OverlayedModal.module.scss';

export interface OverlayedModalProps {
    className?: string;
}

function OverlayedModal({ className, ...props }: ModalProps) {
    return (
        <Modal className={classNames(styles.overlayedModal, className)} {...props} />
    );
}

export default OverlayedModal;
