import {
    SearchSuggestToursResponse,
    TourSearchRequest,
    TourSearchResponse,
    SearchSuggestionsResponse,
} from '@/endurance/tourSearch';

import * as queryString from 'qs';
import searchHelper from '../helpers/searchHelper';
import { getResponse } from './enduranceApi';

export const searchUrl = '/tours/search';
export const tourSuggestionUrl = '/tours/search/suggestTours';
export const searchSuggestionsUrl = '/tours/search/suggestions';

export function search(
    request: TourSearchRequest,
    accessToken: string | undefined
) {
    const filteredParams = searchHelper.removeUnusedEntries(request);
    const query = queryString.stringify(filteredParams, {
        encode: true,
        arrayFormat: 'comma',
    });

    return getResponse<TourSearchResponse>(
        `${searchUrl}?${query}`,
        accessToken
    );
}

export function getSearchSuggestions() {
    return getResponse<SearchSuggestionsResponse>(searchSuggestionsUrl);
}

export function suggestTours(text: string) {
    const query = queryString.stringify(
        { text: text },
        { encode: true, arrayFormat: 'comma' }
    );
    return getResponse<SearchSuggestToursResponse>(
        `${tourSuggestionUrl}?${query}`
    );
}

const tourSearchApi = {
    search,
    suggestTours,
};

export default tourSearchApi;
