import { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';

import { TapEmailAddress } from '../common/TapContactInfo';
import TextInputField from '../common/TextInputField/TextInputField';
import { LoadingButton, PrimaryButton } from '../common/Button';
import OverlayedModal from '../ModalPopups/OverlayedModal/OverlayedModal';
import Error from '../common/Error/Error';
import {
    Action,
    Area,
    InteractionItem,
    Page,
} from '../../utils/analytics/enums';
import { isValidEmail } from '../../utils/validation';
import { sendEventV2 } from '../../utils/analytics/analyticsService';
import { logErrorWithInfo } from '../../utils/sentry';
import { mailingListSignup } from '../../apis/userApi';
import useWindowWidth from '../../hooks/useWindowWidth';
import { useHomePage } from '../../hooks/useHomePage';

import styles from './SignupPopup.module.scss';
import { useLogin } from '../../contexts/LoginContext';

interface FormValues {
    firstName: string;
    lastName: string;
    email: string;
}

const SignupPopup = () => {
    const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
    const [isSubmitFailed, setIsSubmitFailed] = useState(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { isLoggedIn } = useLogin();

    const {
        values,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        handleChange,
        handleSubmit,
    } = useFormik<FormValues>({
        initialValues: initialValues,
        onSubmit: onSubmit,
        validate: validate,
    });

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 768;

    const emailInputRef: any = useRef(null);
    const firstNameInputRef: any = useRef(null);
    const lastNameInputRef: React.MutableRefObject<null> = useRef(null);

    function handleOnKeyUp(
        e: React.KeyboardEvent<HTMLInputElement>,
        nextInputRef: any
    ) {
        if (e.key === 'Enter' && isMobile) {
            nextInputRef.current.focus();
        }
    }

    const { data } = useHomePage();

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const isUsedCookie = Cookies.get('TAP-Signup-Done');
            setIsOpen(isUsedCookie !== 'true' && !isLoggedIn);
        }, 15000);

        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <OverlayedModal
            isOpen={isOpen}
            onIsOpenChange={handleIsOpenChange}
            className={styles.signup}
            modalKey="signupModal"
            hideWidgets={true}
        >
            {data && (
                <picture className={styles.image}>
                    <ImageSizes imageUrl={data.mainImage} />
                    <img
                        src={getCosmicImageUrl(data.mainImage, 380, 620)}
                        alt=""
                        loading="eager"
                        fetchpriority="high"
                    />
                </picture>
            )}
            <div className={styles.content}>
                <h2>Outside, Doing Good Things!</h2>
                {!isSubmitSuccess && (
                    <>
                        <p className={styles.message}>
                            Sign up for discounts, news, offers and more...
                        </p>
                        <form
                            className={styles.modalContent}
                            onSubmit={handleSubmit}
                        >
                            <TextInputField
                                label="Email"
                                name="email"
                                value={values.email}
                                isRequired={true}
                                onChange={handleChange}
                                onBlur={() => {
                                    return;
                                }}
                                placeholder={'email@example.com'}
                                className={styles.emailInput}
                                error={errors.email?.toString()}
                                isTouched={touched.email}
                                inputRef={emailInputRef}
                                onFocus={() => scrollToInput(emailInputRef)}
                                onKeyUp={(
                                    e: React.KeyboardEvent<HTMLInputElement>
                                ) => handleOnKeyUp(e, firstNameInputRef)}
                                type="text"
                                autoComplete="email"
                            />
                            <TextInputField
                                label="First Name"
                                name="firstName"
                                value={values.firstName}
                                isRequired={true}
                                onChange={handleChange}
                                onBlur={() => {
                                    return;
                                }}
                                placeholder={'John'}
                                className={styles.firstNameInput}
                                error={errors.firstName?.toString()}
                                isTouched={touched.firstName}
                                inputRef={firstNameInputRef}
                                onFocus={() => scrollToInput(firstNameInputRef)}
                                onKeyUp={(
                                    e: React.KeyboardEvent<HTMLInputElement>
                                ) => handleOnKeyUp(e, lastNameInputRef)}
                                type="text"
                                autoComplete="given-name"
                            />
                            <TextInputField
                                label="Last Name"
                                name="lastName"
                                value={values.lastName}
                                isRequired={true}
                                onChange={handleChange}
                                onBlur={() => {
                                    return;
                                }}
                                placeholder={'Smith'}
                                className={styles.lastNameInput}
                                error={errors.lastName?.toString()}
                                isTouched={touched.lastName}
                                inputRef={lastNameInputRef}
                                onFocus={() => scrollToInput(lastNameInputRef)}
                                type="text"
                                autoComplete="family-name"
                            />
                            <div className={styles.buttons}>
                                {!isSubmitting ? (
                                    <PrimaryButton
                                        className={styles.submitButton}
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            handleSubmit(e);
                                        }}
                                        type="submit"
                                    >
                                        Signup
                                    </PrimaryButton>
                                ) : (
                                    <LoadingButton
                                        className={styles.loadingButton}
                                    />
                                )}
                            </div>
                        </form>
                    </>
                )}

                {isSubmitFailed && (
                    <Error className={styles.error}>
                        Oops! Something went wrong, if this issue persists
                        please contact us <TapEmailAddress />
                    </Error>
                )}
                {isSubmitSuccess && (
                    <>
                        <p>
                            You will receive an email soon with your discount
                            code.
                        </p>
                    </>
                )}
            </div>
        </OverlayedModal>
    );

    function handleClose() {
        Cookies.set('TAP-Signup-Done', 'true', {
            expires: 365,
        });

        sendEventV2(
            Page.homePage,
            Area.signUpModal,
            InteractionItem.closeButton,
            Action.click,
            null
        );

        setIsOpen(false);
    }

    function handleIsOpenChange(isOpen: boolean) {
        if (!isOpen) {
            handleClose();
        }
    }
    function onSubmit(values: any) {
        mailingListSignup(values.firstName, values.lastName, values.email)
            .then(response => {
                if (response.success) {
                    sendEventV2(
                        Page.homePage,
                        Area.signUpModal,
                        InteractionItem.submit,
                        Action.submit,
                        {
                            firstName: values.firstName,
                            lastName: values.lastName,
                            email: values.email,
                        }
                    );
                    setIsSubmitSuccess(true);
                } else {
                    logErrorWithInfo('Failed to sign up', {
                        response: response,
                    });
                    setIsSubmitFailed(true);
                }
            })
            .catch((error: Error) => {
                logErrorWithInfo(error);
                setIsSubmitFailed(true);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }
};

function scrollToInput(inputRef: any) {
    setTimeout(() => {
        inputRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    }, 200);
}

const initialValues = {
    email: '',
    firstName: '',
    lastName: '',
};

function validate(values: any) {
    const errors: any = {};

    if (!isValidEmail(values.email)) {
        errors.email = 'Please input a valid email address';
    }
    if (!values.firstName) {
        errors.firstName = 'Please input a first name';
    }
    if (!values.lastName) {
        errors.lastName = 'Please input a last name';
    }

    return errors;
}

function ImageSizes({ imageUrl }: { imageUrl: string }) {
    return (
        <>
            <source
                srcSet={getCosmicImageUrl(imageUrl, 400, 800)}
                media="(min-width: 1025px)"
            />
            <source
                srcSet={getCosmicImageUrl(imageUrl, 600, 270)}
                media="(min-width: 440px)"
            />
            <source
                srcSet={getCosmicImageUrl(imageUrl, 390, 200)}
                media="(min-width: 300px)"
            />
        </>
    );
}

function getCosmicImageUrl(
    baseUrl: string,
    width: number,
    height: number
): string {
    return `${baseUrl}?w=${width}&h=${height}&fit=crop&fm=jpg&auto=format,compress`;
}

export default SignupPopup;
