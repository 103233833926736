import { useMemo } from 'react';
import { MenuLink } from './MenuLink';

import { useAppSelector } from '../../../hooks/useAppSelector';

export function useDealLinks() {
    const isLoading = useAppSelector(({ site }) => site.isLoading);
    const dealsTitle = useAppSelector(
        ({ site }) => site.header?.dealsMenuTitle ?? 'Deals'
    );
    const dealsUrl = useAppSelector(
        ({ site }) => site.header?.dealsMenuUrl ?? '/deals'
    );
    const deals = useAppSelector(({ site }) => site.header?.dealsLinks ?? []);
    const dealsImageUrl = useAppSelector(
        ({ site }) => site.dealsImageUrl ?? null
    );

    const dealLinks: MenuLink[] = useMemo(
        () => [
            ...deals.map(({ title, slug, imageUrl }) => ({
                title,
                url: `/deals/${slug}`,
                imageUrl,
                subLinks: [],
            })),
            {
                title: 'All Deals',
                url: dealsUrl,
                imageUrl: dealsImageUrl,
                subLinks: [],
            },
        ],
        [deals, dealsUrl, dealsImageUrl]
    );

    return {
        isLoading,
        dealsTitle,
        dealsUrl,
        dealLinks,
    };
}
