import { Blog } from '@/endurance/content';

import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { getBlog } from '../../apis/blogApi.v2';
import { logErrorWithCustomMessage } from '../../utils/sentry';

interface BlogContextValue {
    isLoading: boolean;
    hasError: boolean;
    blog: Blog | null;
    slug: string;
}

export interface BlogProviderProps {
    children: ReactNode;
}

const initialContextValue: BlogContextValue = {
    isLoading: true,
    hasError: false,
    blog: null,
    slug: '',
};

export interface BlogV2Params {
    slug: string;
}

export const BlogContext = createContext<BlogContextValue>(initialContextValue);

export function useBlog() {
    const context = useContext(BlogContext);
    if (!context) {
        throw new Error('No Blog Context initialised.');
    }
    return context;
}

export function BlogProvider({ children }: BlogProviderProps) {
    const [isLoading, setIsLoading] = useState<boolean | null>(null);
    const [hasError, setHasError] = useState<boolean>(false);
    const [blog, setBlog] = useState<Blog | null>(null);
    const { slug } = useParams<BlogV2Params>();

    useEffect(() => {
        if (isLoading === null) {
            setIsLoading(true);
            setHasError(false);
            setBlog(null);

            getBlog(slug)
                .then(response => {
                    if (response.isSuccess && response.result !== null) {
                        setBlog(response.result);
                    } else {
                        throw new Error('Failed to fetch blog');
                    }
                })
                .catch((error: Error) => {
                    logErrorWithCustomMessage(error, 'Failed to fetch blog', {
                        slug,
                    });
                    setHasError(true);
                })
                .finally(() => setIsLoading(false));
        }
    }, [isLoading, slug]);

    useEffect(() => {
        setIsLoading(null);
    }, [slug]);

    return (
        <BlogContext.Provider
            value={{
                isLoading: isLoading ?? true,
                hasError,
                slug,
                blog,
            }}
        >
            {children}
        </BlogContext.Provider>
    );
}
