import { imageDomain } from '../utils/config';

export const replaceSizeTags = (href: string, width: number, height: number) =>
    href !== undefined
        ? href
              .replace('{width}', width.toString())
              .replace('{height}', height.toString())
        : '';

const stripSizeTags = (href: string) => href.replace('{width}x{height}/', '');

export const appendImageQueryParams = (
    href: string,
    width: number,
    height: number,
    cropImage: boolean
) => {
    const imageUrl = new URL(href);

    if (width) {
        imageUrl.searchParams.append('imageWidth', width.toString());
    }
    if (height) {
        imageUrl.searchParams.append('imageHeight', height.toString());
    }

    imageUrl.searchParams.append('cropImage', cropImage.toString());

    return imageUrl.href;
};

export const encodeImageRequest = (
    href: string,
    width: number,
    height: number,
    resizeMode?: string
) => {
    var request: Request = {
        key: stripSizeTags(href).replace(imageDomain, ''),
        edits: {
            resize: {
                width: width,
                height: height,
            },
        },
    };

    if (resizeMode) {
        request.edits.resize.fit = resizeMode;
    }

    return `${imageDomain}${btoa(JSON.stringify(request))}`;
};

const imageUrl = {
    replaceSizeTags,
    encodeImageRequest,
};

interface Request {
    key: string;
    edits: {
        resize: {
            width: number;
            height: number;
            fit?: string;
        };
    };
}

export default imageUrl;
