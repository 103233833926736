import SubMenu from '../SubMenu/SubMenu';
import { useAdventureLinks } from '../../useAdventureLinks';
import { MenuProvider } from '../../../../../contexts/MenuContext';

export interface AdventuresLinkProps {
    className?: string;
}

function AdventuresLink({ className }: AdventuresLinkProps) {
    const { isLoading, adventureLinks } = useAdventureLinks();

    if (isLoading) {
        return null;
    }

    return (
        <MenuProvider>
            <SubMenu
                className={className}
                title="Adventures"
                url="/adventures"
                icon="personHiking"
                links={adventureLinks}
            />
        </MenuProvider>
    );
}

export default AdventuresLink;
