import { HomePageData } from '@/apis/contentApi';

import { useState, useEffect } from 'react';
import contentApi from '../apis/contentApi';

export function useHomePage() {
    const [homePageData, setHomePageData] = useState<HomePageData | null>(null);
    const [isSuccess, setIsSuccess] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        setIsLoading(true);
        contentApi
            .getHomepage()
            .then(result => {
                setIsSuccess(true);
                setHomePageData(result);
            })
            .catch((error: Error) => {
                setErrors([error.message]);
                setIsSuccess(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [setHomePageData]);

    return {
        isLoading,
        isSuccess,
        isNotFound: false,
        data: homePageData,
        errors,
    };
}
