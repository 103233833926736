import {
    ConfirmPaymentRequest,
    ConfirmPaymentResponse,
    CreateBookingRequest,
    CreateBookingResponse,
} from '@/endurance/bookings';
import {
    PaymentPlanRequest,
    PaymentPlanResponse,
    PromotionalDiscountRequest,
    PromotionalDiscountResponse,
} from '@/endurance/billing';

import { tapApiAuthKey, tapApiBaseUrl } from '../utils/config';
import { getTrackingCodes } from '../utils/tracking/trackingService';
import * as queryString from 'qs';

const createBookingUrl = `${tapApiBaseUrl}/bookings`;
const confirmPaymentUrl = `${tapApiBaseUrl}/bookings/confirmpayment`;
const paymentPlanUrl = `${tapApiBaseUrl}/billing/paymentPlan`;
const promoCodeUrl = `${tapApiBaseUrl}/billing/promotions`;

export function createBooking(
    bookingRequest: CreateBookingRequest,
    accessToken?: string
): Promise<CreateBookingResponse> {
    bookingRequest.trackingCodes = getTrackingCodes();
    return fetch(createBookingUrl, {
        body: JSON.stringify(bookingRequest),
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AuthKey': tapApiAuthKey,
            'X-AccessToken': accessToken ?? '',
        },
        cache: 'no-cache',
    }).then(response => response.json());
}

export function createHoldSpace(
    holdSpaceData: CreateBookingRequest,
    accessToken?: string
): Promise<CreateBookingResponse> {
    holdSpaceData.trackingCodes = getTrackingCodes();

    const request: RequestInit = {
        body: JSON.stringify(holdSpaceData),
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AuthKey': tapApiAuthKey,
            'X-AccessToken': accessToken ?? '',
        },
        cache: 'no-cache',
    };

    return fetch(createBookingUrl, request).then(response => response.json());
}

export function confirmPayment(
    paymentProviderToken: string,
    bookingRef: string
): Promise<ConfirmPaymentResponse> {
    const request: ConfirmPaymentRequest = {
        bookingRef,
        paymentProviderToken,
    };
    return fetch(confirmPaymentUrl, {
        body: JSON.stringify(request),
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AuthKey': tapApiAuthKey,
        },
        cache: 'no-cache',
    }).then(response => response.json());
}

export function getPaymentPlan(
    paymentPlanRequest: PaymentPlanRequest,
    accessToken?: string
): Promise<PaymentPlanResponse> {
    const query = queryString.stringify(paymentPlanRequest, {
        encode: false,
        allowDots: true,
    });

    return fetch(`${paymentPlanUrl}?${query}`, {
        headers: {
            'X-AuthKey': tapApiAuthKey,
            'X-AccessToken': accessToken ?? '',
        },
    }).then(response => response.json());
}

export function applyPromoCode(
    promoCode: string,
    bookingValue: number,
): Promise<PromotionalDiscountResponse> {
    var params: PromotionalDiscountRequest = {
        promoCode: promoCode,
        bookingValue: bookingValue
    };
    const query = queryString.stringify(params, {
        encode: false,
        allowDots: true,
    });

    return fetch(`${promoCodeUrl}?${query}`, {
        headers: {
            'X-AuthKey': tapApiAuthKey,
        },
    }).then(response => {
        return response.json();
    });
}

const checkoutApi = {
    confirmPayment,
    createBooking,
    createHoldSpace,
    getPaymentPlan,
    applyPromoCode,
};

export default checkoutApi;
