import { Blog, BlogsResult } from '@/endurance/content';

import { get } from './enduranceApi';
import QueryString from 'qs';

const blogsUrl = '/v2/content/blogs';

export async function getBlog(slug: string) {
    return await get<Blog>(`${blogsUrl}/${slug}`);
}

export interface GetBlogsParams {
    page: number;
    pageSize: number;
    tag?: string;
}

export async function getBlogs(
    page: number,
    pageSize: number,
    tagSlug?: string
) {
    const params: GetBlogsParams = { page, pageSize, tag: tagSlug };
    const query = QueryString.stringify(params, { addQueryPrefix: true });
    return await get<BlogsResult>(`${blogsUrl}${query}`);
}
