import { Currency } from '@/core';

import { useRef, useState } from 'react';
import classNames from 'classnames';
import {
    autoUpdate,
    flip,
    FloatingFocusManager,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useListNavigation,
    useRole,
    useTransitionStatus,
} from '@floating-ui/react';
import Option from './Option/Option';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { resetPromoCode, setCurrency } from '../../../../../redux/actions';
import { selectCurrency } from '../../../../../redux/selectors/site.selectors';
import { sendEventV2 } from '../../../../../utils/analytics/analyticsService';
import {
    Action,
    Area,
    InteractionItem,
    Page,
} from '../../../../../utils/analytics/enums';
import styles from './CurrencySelector.module.scss';

export interface CurrencySelectorProps {
    className?: string;
}

function CurrencySelector({ className }: CurrencySelectorProps) {
    const dispatch = useAppDispatch();
    const selectedCurrency = useAppSelector(state =>
        selectCurrency(state.site)
    );
    const currencies = useAppSelector(({ site }) => site.currencies);
    const selectedIndex = selectedCurrency
        ? currencies.findIndex(c => c.code === selectedCurrency.code)
        : null;
    const [isOpen, setIsOpen] = useState(false);
    const listRef = useRef<HTMLElement[]>([]);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const { context, refs, floatingStyles } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [flip(), shift()],
        strategy: 'absolute',
        placement: 'bottom-start',
        whileElementsMounted: autoUpdate,
    });

    const click = useClick(context);
    const role = useRole(context, { role: 'select' });
    const dismiss = useDismiss(context);
    const listNavigation = useListNavigation(context, {
        listRef,
        activeIndex,
        selectedIndex,
        onNavigate: setActiveIndex,
        focusItemOnHover: false,
        allowEscape: true,
        openOnArrowKeyDown: true,
    });
    const { getReferenceProps, getFloatingProps, getItemProps } =
        useInteractions([click, dismiss, role, listNavigation]);
    const { isMounted, status: mountedStatus } = useTransitionStatus(context, {
        duration: 100,
    });

    if (!selectedCurrency) {
        return null;
    }

    return (
        <>
            <button
                ref={refs.setReference}
                className={classNames(styles.currencySelector, className)}
                title="Select currency"
                {...getReferenceProps()}
            >
                <img src={selectedCurrency.flag} alt="" />
                <span className={styles.name}>{selectedCurrency.name}</span>
            </button>
            {isMounted && (
                <FloatingFocusManager context={context}>
                    <div
                        ref={refs.setFloating}
                        className={styles.popup}
                        style={floatingStyles}
                        data-status={mountedStatus}
                        {...getFloatingProps()}
                    >
                        {currencies.map((currency, index) => (
                            <Option
                                key={currency.code}
                                ref={(node: HTMLElement) => {
                                    listRef.current[index] = node;
                                }}
                                className={styles.option}
                                currency={currency}
                                isSelected={
                                    currency.code === selectedCurrency.code
                                }
                                tabIndex={activeIndex === index ? 0 : -1}
                                onSelectCurrency={handleSelectCurrency}
                                {...getItemProps()}
                            />
                        ))}
                    </div>
                </FloatingFocusManager>
            )}
        </>
    );

    function handleSelectCurrency(currency: Currency) {
        dispatch(setCurrency(currency.code));
        dispatch(resetPromoCode);
        setIsOpen(false);
        setActiveIndex(null);
        sendEventV2(
            Page.header,
            Area.mobileMenu,
            InteractionItem.currencySelector,
            Action.select,
            {
                currencyCode: currency.code,
            }
        );
    }
}

export default CurrencySelector;
