import DesktopMenu from '../DesktopMenu/DesktopMenu';
import { DesktopMenuProvider } from '../DesktopMenu/DesktopMenuContext';
import { useDealLinks } from '../../useDealLinks';

export interface DealsMenuProps {
    className?: string;
}

function DealsMenu({ className }: DealsMenuProps) {
    const { isLoading, dealsTitle, dealLinks } = useDealLinks();

    if (isLoading) {
        return null;
    }

    return (
        <DesktopMenuProvider>
            <DesktopMenu className={className} links={dealLinks}>
                {dealsTitle}
            </DesktopMenu>
        </DesktopMenuProvider>
    );
}

export default DealsMenu;
