import { SimpleBlog } from '@/endurance/content';

import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';
import { getBlogs } from '../../apis/blogApi.v2';
import { logErrorWithCustomMessage } from '../../utils/sentry';

const pageSize = 15;

interface BlogsContextValue {
    isLoading: boolean;
    hasError: boolean;
    blogs: SimpleBlog[];
    total: number;
    page: number;
    pageSize: number;
    isLoadingMore: boolean;
    tagSlug?: string;
    onLoadMore: () => void;
}

export interface BlogsProviderProps {
    children: ReactNode;
}

const initialContextValue: BlogsContextValue = {
    isLoading: true,
    hasError: false,
    page: 1,
    pageSize: pageSize,
    tagSlug: undefined,
    blogs: [],
    total: 0,
    onLoadMore: () => {},
    isLoadingMore: false,
};

export interface BlogsV2Params {
    tagSlug?: string;
}

export const BlogsContext =
    createContext<BlogsContextValue>(initialContextValue);

export function useBlogs() {
    const context = useContext(BlogsContext);
    if (!context) {
        throw new Error('No Blogs Context initialised.');
    }
    return context;
}

export function BlogsProvider({ children }: BlogsProviderProps) {
    const [isLoading, setIsLoading] = useState<boolean | null>(null);
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [blogs, setBlogs] = useState<SimpleBlog[]>([]);
    const [total, setTotal] = useState<number>(0);
    const history = useHistory();
    const query = useQuery();
    const { tagSlug } = useParams<BlogsV2Params>();

    const page = parseInt(query.get('page') ?? '1', 10);

    useEffect(() => {
        if (isLoading === null) {
            setIsLoading(true);
            setHasError(false);
            setBlogs([]);
            setTotal(0);

            getBlogs(1, pageSize * page, tagSlug)
                .then(response => {
                    if (response.isSuccess && response.result !== null) {
                        setBlogs(response.result.blogs);
                        setTotal(response.result.total);
                    } else {
                        throw new Error('Failed to fetch blogs');
                    }
                })
                .catch((error: Error) => {
                    logErrorWithCustomMessage(error, 'Failed to fetch blogs', {
                        tagSlug,
                        page,
                    });
                    setHasError(true);
                })
                .finally(() => setIsLoading(false));
        }
    }, [isLoading, tagSlug, page]);

    useEffect(() => {
        setIsLoading(null);
    }, [tagSlug]);

    return (
        <BlogsContext.Provider
            value={{
                isLoading: isLoading ?? true,
                hasError,
                page,
                pageSize,
                tagSlug,
                blogs,
                total,
                onLoadMore: handleLoadMore,
                isLoadingMore,
            }}
        >
            {children}
        </BlogsContext.Provider>
    );

    function handleLoadMore() {
        const newPage = page + 1;

        setIsLoadingMore(true);
        getBlogs(newPage, pageSize, tagSlug)
            .then(response => {
                if (response.isSuccess && response.result !== null) {
                    setBlogs([...blogs, ...response.result.blogs]);
                } else {
                    throw new Error('Failed to fetch more blogs');
                }
            })
            .catch((error: Error) => {
                logErrorWithCustomMessage(error, 'Failed to fetch more blogs', {
                    tagSlug,
                    page,
                });
            })
            .finally(() => setIsLoadingMore(false));

        if (tagSlug !== undefined) {
            history.replace(`/blogs/tag/${tagSlug}?page=${newPage}`);
        } else {
            history.replace(`/blogs?page=${newPage}`);
        }
    }
}
