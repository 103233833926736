import classNames from 'classnames';
import CircularIconButton from '../../../common/CircularIconButton/CircularIconButton';
import { sendEventV2 } from '../../../../utils/analytics/analyticsService';
import {
    Action,
    Area,
    InteractionItem,
    Page,
} from '../../../../utils/analytics/enums';
import { useMenu } from '../../../../contexts/MenuContext';
import styles from './MobileSearchButton.module.scss';
import MobileSearchMenu from '../MobileSearchMenu/MobileSearchMenu';
import { FloatingTree } from '@floating-ui/react';

export interface MobileSearchButtonProps {
    className?: string;
}

function MobileSearchButton({ className }: MobileSearchButtonProps) {
    const { getReferenceProps, refs, setIsOpen } = useMenu();

    return (
        <FloatingTree>
            <CircularIconButton
                ref={refs.setReference}
                className={classNames(styles.mobileSearchButton, className)}
                icon="search"
                aria-label="Open search menu"
                {...getReferenceProps({ onClick: handleClick })}
            />
            <MobileSearchMenu />
        </FloatingTree>
    );

    function handleClick() {
        setIsOpen(true);
        sendEventV2(
            Page.header,
            Area.navigation,
            InteractionItem.searchButton,
            Action.click,
            null
        );
    }
}

export default MobileSearchButton;
