import { MenuLink } from '../../../MenuLink';

import { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { useDesktopMenu } from '../DesktopMenuContext';
import { desktopHeight } from '../../../Header.v2.constants';
import MenuItem from './MenuItem/MenuItem';
import SubMenu from './SubMenu/SubMenu';
import styles from './FloatingMenu.module.scss';

export interface FloatingMenuProps {
    links: MenuLink[];
    className?: string;
}

function FloatingMenu({ links, className }: FloatingMenuProps) {
    const {
        getFloatingProps,
        getItemProps,
        listRef,
        selectedMenuIndex,
        setFloating,
        mountedStatus,
        isMounted,
    } = useDesktopMenu();

    if (!isMounted) {
        return null;
    }

    const selectedLink = links[selectedMenuIndex] ?? null;
    const hasOnlyLinks = links.every(({ subLinks }) => subLinks.length === 0);

    return (
        <nav
            className={classNames(
                styles.floatingMenu,
                { [styles.hasOnlyLinks]: hasOnlyLinks },
                className
            )}
            ref={setFloating}
            style={
                {
                    '--desktop-height': `${desktopHeight}px`,
                } as React.CSSProperties
            }
            data-status={mountedStatus}
            {...getFloatingProps()}
        >
            <div className={styles.menu}>
                {links.map(({ title, url, subLinks }, index) => {
                    const props: HTMLAttributes<HTMLElement> = getItemProps();

                    if (hasOnlyLinks) {
                        props.onMouseOver = props.onClick;
                    }

                    return (
                        <MenuItem
                            key={url}
                            title={title}
                            url={url}
                            isSelected={
                                selectedMenuIndex === index &&
                                subLinks !== undefined
                            }
                            isLink={subLinks.length === 0}
                            className={styles.menuItem}
                            tabIndex={selectedMenuIndex === index ? 0 : -1}
                            ref={(node: HTMLElement) => {
                                listRef.current[index] = node;
                            }}
                            {...props}
                        />
                    );
                })}
            </div>
            {selectedLink && (
                <SubMenu className={styles.submenu} link={selectedLink} />
            )}
        </nav>
    );
}

export default FloatingMenu;
