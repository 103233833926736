import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from './LoadingText.module.scss';

export type LoadingTextProps = {
    text?: string;
} & React.HTMLAttributes<HTMLDivElement>;

function LoadingText({
    text = 'Loading',
    className,
    ...props
}: LoadingTextProps) {
    const [dots, setDots] = useState('');
    useEffect(() => {
        const interval = setInterval(() => {
            if (dots.length < 3) {
                setDots(dots + '.');
            } else {
                setDots('');
            }
        }, 600);
        return () => clearInterval(interval);
    }, [dots]);

    return (
        <div className={classNames(styles.loadingText, className)} {...props}>
            <span>{text}</span>
            <span className={styles.dots}>{dots}</span>
        </div>
    );
}

export default LoadingText;
