import packageJson from '../../package.json';

export const environment = process.env.REACT_APP_ENVIRONMENT;
export const appVersion = packageJson.version;
export const authServer = process.env.REACT_APP_COGNITO_AUTHSERVER;
export const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
export const imageDomain = 'https://images.theadventurepeople.com/';
export const isDevelopment = environment === 'Development';
export const isProduction = environment === 'Production';
export const stripeKey = process.env.REACT_APP_STRIPE_KEY;
export const tapApiBaseUrl = process.env.REACT_APP_TAP_API_URL;
export const tapApiAuthKey = process.env.REACT_APP_TAP_API_AUTH_KEY;
export const tapRedirectionApiBaseUrl =
    process.env.REACT_APP_TAP_REDIRECTION_API_URL;
export const googleAnalyticsTrackingId =
    process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
export const tawkId = process.env.REACT_APP_TAWK_TO_ID;
export const tawkEnabled =
    process.env.REACT_APP_TAWK_ENABLED === 'true' && tawkId !== undefined;
export const cosmicSiteSlug = process.env.REACT_APP_COSMIC_SITE_SLUG;
export const isTextSearchTourSuggestionsEnabled =
    process.env.REACT_APP_TEXT_SEARCH_TOUR_SUGGESTIONS_ENABLED === 'true';
export const googleTagManagerId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
export const googleTagManagerAuth =
    process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH;
export const googleTagManagerEnvironment =
    process.env.REACT_APP_GOOGLE_TAG_MANAGER_ENVIRONMENT;
export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const googleStaticMapsApiKey =
    process.env.REACT_APP_GOOGLE_STATIC_MAPS_API_KEY;
export const areFlightsHotelsEnabled =
    process.env.REACT_APP_FLIGHTS_HOTEL_ENABLED === 'true';

export const liveChatLicenseId = process.env.REACT_APP_LIVECHAT_LICENSE_ID;
export const isOptinmonsterEnabled =
    process.env.REACT_APP_OPTINMONSTER_ENABLED === 'true';

export const collectBrochureUserDetails =
    process.env.REACT_APP_COLLECT_BROCHURE_USER_DETAILS === 'true';

export const isPreRender = () =>
    navigator.userAgent.toLowerCase().indexOf('prerender') >= 0;

export const isBot = () =>
    navigator.userAgent.toLowerCase().indexOf('bot') >= 0;

export const isLoginEnabled = process.env.REACT_APP_LOGIN_ENABLED === 'true';

export const loginRedirectUri =
    process.env.REACT_APP_COGNITO_LOGIN_REDIRECT_URI;
export const logoutRedirectUri =
    process.env.REACT_APP_COGNITO_LOGOUT_REDIRECT_URI;

export const conversionsEnabled =
    process.env.REACT_APP_CONVERSIONS_ENABLED === 'true';

export const cookieBannerEnabled =
    process.env.REACT_APP_COOKIE_BANNER_ENABLED === 'true';

export const accommodationEnabled =
    process.env.REACT_APP_ACCOMMODATION_ENABLED === 'true';

export const tourPageVersion =
    process.env.REACT_APP_TOUR_PAGE_VERSION === '2' ? 2 : 1;

export const homePageVersion =
    process.env.REACT_APP_HOME_PAGE_VERSION === '2' ? 2 : 1;

export const tourSearchPageVersion =
    process.env.REACT_APP_TOUR_SEARCH_PAGE_VERSION === '2' ? 2 : 1;

export const headerVersion =
    process.env.REACT_APP_HEADER_VERSION === '2' ? 2 : 1;

export const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

export const imageHandlerV2URL = process.env.REACT_APP_IMAGE_HANDLER_V2_URL;

const config = {
    appVersion,
    authServer,
    conversionsEnabled,
    cosmicSiteSlug,
    clientId,
    environment,
    googleAnalyticsTrackingId,
    googleTagManagerAuth,
    googleTagManagerEnvironment,
    googleTagManagerId,
    googleMapsApiKey,
    googleStaticMapsApiKey,
    homePageVersion,
    imageDomain,
    isDevelopment,
    isPreRender,
    isBot,
    isProduction,
    stripeKey,
    tapApiBaseUrl,
    tapApiAuthKey,
    tapRedirectionApiBaseUrl,
    tawkEnabled,
    tawkId,
    isTextSearchTourSuggestionsEnabled,
    areFlightsHotelsEnabled,
    liveChatLicenseId,
    isOptinmonsterEnabled,
    isLoginEnabled,
    loginRedirectUri,
    logoutRedirectUri,
    cookieBannerEnabled,
    accommodationEnabled,
    tourPageVersion,
    mixpanelToken,
    imageHandlerV2URL,
    headerVersion,
};

export default config;
