import { useFeatureFlag } from '../../contexts/FeatureFlagContext';
import config from '../../utils/config';
import HeaderV1Hoc from './Header.v1/Header.v1.Hoc';
import HeaderV2 from './Header.v2/Header.v2';

export interface HeaderProps {}

function Header() {
    const { features } = useFeatureFlag();

    const headerVersion =
        features?.siteWideHeaderVersion ?? config.headerVersion;
    return headerVersion === 2 ? <HeaderV2 /> : <HeaderV1Hoc />;
}

export default Header;
