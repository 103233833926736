import SubMenu from '../SubMenu/SubMenu';
import { useDealLinks } from '../../useDealLinks';
import { MenuProvider } from '../../../../../contexts/MenuContext';

export interface DealsLinkProps {
    className?: string;
}

function DealsLink({ className }: DealsLinkProps) {
    const { isLoading, dealsTitle, dealsUrl, dealLinks } = useDealLinks();

    if (isLoading) {
        return null;
    }

    return (
        <MenuProvider>
            <SubMenu
                className={className}
                title={dealsTitle}
                url={dealsUrl}
                icon="deals"
                links={dealLinks}
            />
        </MenuProvider>
    );
}

export default DealsLink;
