import { Activity } from '@/apis/contentApi';
import { MenuLink } from './MenuLink';

import { useMemo } from 'react';
import { useAppSelector } from '../../../hooks/useAppSelector';

export function useAdventureLinks() {
    const isLoading = useAppSelector(({ site }) => site.isLoading);
    const activities = useAppSelector(({ site }) => site.activities ?? []);
    const popularActivitiesImageUrl = useAppSelector(
        ({ site }) => site.popularActivitiesImageUrl ?? null
    );

    const adventureLinks: MenuLink[] = useMemo(
        () => getActivityLinks(activities, popularActivitiesImageUrl),
        [activities, popularActivitiesImageUrl]
    );

    return {
        isLoading,
        adventureLinks,
    };
}

function getActivityLinks(
    activities: Activity[],
    popularActivitiesImageUrl: string | null
): MenuLink[] {
    const activityLinks: MenuLink[] = [
        {
            title: 'Popular',
            url: '/activities/popular',
            subLinks: activities
                .filter(({ isPopular }) => isPopular)
                .sort(sortByTitle)
                .map(({ title, slug, imageUrl }) => ({
                    title: title,
                    url: `/activities/${slug}`,
                    imageUrl,
                    subLinks: [],
                })),
            imageUrl: popularActivitiesImageUrl,
        },
        buildActivityLink('18-30-somethings', "Youth 20's - 30's", activities),
        buildActivityLink('40s-and-over', '40s and over', activities),
        buildActivityLink('solo-travellers', 'Solo Travellers', activities),
        buildActivityLink('family', 'Family', activities),
        buildActivityLink('gap-year', 'GAP Year', activities),
        buildActivityLink('trekking', 'Trekking', activities),
        buildActivityLink('cycling', 'Cycling', activities),
        buildActivityLink('sailing', 'Sailing', activities),
        buildActivityLink('multi-activity', 'Multi Activity', activities),
        buildActivityLink('wildlife', 'Wildlife', activities),
        buildActivityLink('premium', 'Premium', activities),
        buildActivityLink('polar-exploring', 'Polar', activities),
        {
            title: 'View all adventures',
            url: '/activities',
            imageUrl: null,
            subLinks: [],
        },
    ];

    return activityLinks;
}

function buildActivityLink(
    slug: string,
    title: string,
    activities: Activity[]
): MenuLink {
    return {
        title,
        url: `/activities/${slug}`,
        imageUrl:
            activities.find(activity => activity.slug === slug)?.imageUrl ??
            null,
        subLinks: [],
    };
}

function sortByTitle(a: { title: string }, b: { title: string }) {
    const titleA = a.title.toLowerCase();
    const titleB = b.title.toLowerCase();

    if (titleA < titleB) {
        return -1;
    }
    if (titleA > titleB) {
        return 1;
    }

    return 0;
}
