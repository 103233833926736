import { useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
    FloatingFocusManager,
    FloatingOverlay,
    FloatingPortal,
} from '@floating-ui/react';
import Icon from '../../../common/Icon/Icon';
import CurrencySelector from './CurrencySelector/CurrencySelector';
import { PrimaryButton } from '../../../common/Button';
import PhoneLink from './PhoneLink/PhoneLink';
import MenuItem from './MenuItem/MenuItem';
import AdventuresLink from './AdventuresLink/AdventuresLink';
import DealsLink from './DealsLink/DealsLink';
import DestinationsLink from './DestinationsLink/DestinationsLink';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import { useLogin } from '../../../../contexts/LoginContext';
import { useMenu } from '../../../../contexts/MenuContext';
import { sendEventV2 } from '../../../../utils/analytics/analyticsService';
import {
    Action,
    Area,
    InteractionItem,
    Page,
} from '../../../../utils/analytics/enums';
import { mobileHeight } from '../Header.v2.constants';
import logoImage from '../../../../assets/images/the-adventure-people-logo-full.svg';
import styles from './MobileMenu.module.scss';

function MobileMenu() {
    const windowWidth = useWindowWidth();
    const { isLoggedIn, isTrade } = useLogin();
    const {
        nodeId,
        refs,
        isMounted,
        mountedStatus,
        context,
        setIsOpen,
        getFloatingProps,
    } = useMenu();

    useEffect(() => {
        if (windowWidth > 1024) {
            setIsOpen(false);
        }
    }, [windowWidth, setIsOpen]);

    if (nodeId === undefined || context === undefined || !isMounted) {
        return null;
    }

    return (
        <FloatingPortal id="mobile-menu-portal">
            <FloatingOverlay className={styles.overlay} lockScroll={true} />
            <FloatingFocusManager context={context}>
                <div
                    ref={refs.setFloating}
                    className={styles.mobileMenu}
                    style={
                        {
                            '--mobile-height': `${mobileHeight}px`,
                        } as React.CSSProperties
                    }
                    data-status={mountedStatus}
                    {...getFloatingProps()}
                >
                    <nav className={styles.header}>
                        <Link
                            to="/"
                            className={styles.home}
                            onClick={handleBrandClick}
                        >
                            <img
                                loading="eager"
                                fetchpriority="high"
                                alt="Home"
                                src={logoImage}
                            />
                        </Link>
                        <button
                            className={styles.closeButton}
                            onClick={handleClose}
                        >
                            <Icon icon="x" />
                        </button>
                    </nav>
                    <div className={classNames(styles.menuGroup, styles.links)}>
                        <DealsLink />
                        <DestinationsLink />
                        <AdventuresLink />
                    </div>
                    <div className={classNames(styles.menuGroup, styles.user)}>
                        {isTrade ? (
                            <MenuItem
                                icon="headset"
                                as={Link}
                                to="/trade/dashboard"
                            >
                                Agent Dashboard
                            </MenuItem>
                        ) : (
                            <MenuItem
                                icon="userEmpty"
                                as={Link}
                                to="/myaccount"
                            >
                                {isLoggedIn ? 'My Account' : 'Sign in'}
                            </MenuItem>
                        )}
                        <MenuItem icon="heartLight" as={Link} to="/favourites">
                            Favourites
                        </MenuItem>
                        <CurrencySelector className={styles.currencySelector} />
                    </div>
                    <div
                        className={classNames(styles.menuGroup, styles.contact)}
                    >
                        <PhoneLink className={styles.phoneLink} />
                        <PrimaryButton
                            className={styles.enquireButton}
                            as={Link}
                            to="/contact-us"
                            onClick={handleEnquire}
                        >
                            Enquire
                        </PrimaryButton>
                    </div>
                    {!isTrade && (
                        <div
                            className={classNames(
                                styles.menuGroup,
                                styles.trade
                            )}
                        >
                            <MenuItem
                                icon="headset"
                                as={Link}
                                to="/trade/signup"
                            >
                                Trade Agent Registration
                            </MenuItem>
                        </div>
                    )}
                </div>
            </FloatingFocusManager>
        </FloatingPortal>
    );

    function handleClose() {
        setIsOpen(false);
        sendEventV2(
            Page.header,
            Area.mobileMenu,
            InteractionItem.closeButton,
            Action.click,
            null
        );
    }

    function handleEnquire() {
        setIsOpen(false);
        sendEventV2(
            Page.header,
            Area.mobileMenu,
            InteractionItem.enquireButton,
            Action.click,
            null
        );
    }

    function handleBrandClick() {
        setIsOpen(false);
        sendEventV2(
            Page.header,
            Area.mobileMenu,
            InteractionItem.homeLink,
            Action.click,
            null
        );
    }
}

export default MobileMenu;
