import classNames from 'classnames';
import { FloatingTree } from '@floating-ui/react';
import Icon from '../../../common/Icon/Icon';
import { sendEventV2 } from '../../../../utils/analytics/analyticsService';
import {
    Action,
    Area,
    InteractionItem,
    Page,
} from '../../../../utils/analytics/enums';
import { useMenu } from '../../../../contexts/MenuContext';
import MobileMenu from '../MobileMenu/MobileMenu';
import styles from './MobileMenuButton.module.scss';

export interface MobileMenuButtonProps {
    className?: string;
}

function MobileMenuButton({ className }: MobileMenuButtonProps) {
    const { getReferenceProps, refs, setIsOpen } = useMenu();

    return (
        <FloatingTree>
            <button
                ref={refs.setReference}
                className={classNames(styles.mobileMenuButton, className)}
                aria-label="Open menu"
                {...getReferenceProps({ onClick: handleClick })}
            >
                <Icon icon="menu" />
            </button>
            <MobileMenu />
        </FloatingTree>
    );

    function handleClick() {
        setIsOpen(true);
        sendEventV2(
            Page.header,
            Area.menuButton,
            InteractionItem.menuButton,
            Action.open,
            null
        );
    }
}

export default MobileMenuButton;
