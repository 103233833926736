import DesktopMenu from '../DesktopMenu/DesktopMenu';
import { DesktopMenuProvider } from '../DesktopMenu/DesktopMenuContext';
import { useAdventureLinks } from '../../useAdventureLinks';

export interface AdventuresMenuProps {
    className?: string;
}

function AdventuresMenu({ className }: AdventuresMenuProps) {
    const { isLoading, adventureLinks } = useAdventureLinks();

    if (isLoading) {
        return null;
    }

    return (
        <DesktopMenuProvider>
            <DesktopMenu className={className} links={adventureLinks}>
                Adventures
            </DesktopMenu>
        </DesktopMenuProvider>
    );
}

export default AdventuresMenu;
