import styles from './Error.module.scss';
import classNames from 'classnames';
import { TapPhoneNumber } from '../TapContactInfo';

export type ErrorProps = {
    children?: React.ReactNode;
    className?: string;
} & React.HTMLAttributes<HTMLElement>;

const Error = ({ className, children, ...props }: ErrorProps) => {
    return (
        <section className={classNames(styles.error, className)} {...props}>
            {children ? (
                children
            ) : (
                <p>
                    Oops! Something went wrong. Please try again or contact us
                    on <TapPhoneNumber />.
                </p>
            )}
        </section>
    );
};

export default Error;
