import SubMenu from '../SubMenu/SubMenu';
import { useDestinationLinks } from '../../useDestinationLinks';
import { MenuProvider } from '../../../../../contexts/MenuContext';

export interface DestinationsLinkProps {
    className?: string;
}

function DestinationsLink({ className }: DestinationsLinkProps) {
    const { isLoading, destinationLinks } = useDestinationLinks();

    if (isLoading) {
        return null;
    }

    return (
        <MenuProvider>
            <SubMenu
                className={className}
                title="Destinations"
                url="/destinations"
                icon="mapMarker"
                links={destinationLinks}
            />
        </MenuProvider>
    );
}

export default DestinationsLink;
