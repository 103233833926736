import React from 'react';
import { NavLink } from 'react-router-dom';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext';

const NavLinks = () => {
    const { features } = useFeatureFlag();
    return (
        <ul>
            <li>
                <NavLink className="footer-link" to="/about-us">
                    About Us
                </NavLink>
            </li>
            <li>
                <NavLink className="footer-link" to="/abta-member">
                    ABTA member
                </NavLink>
            </li>
            <li>
                <NavLink className="footer-link" to="/atol">
                    ATOL member
                </NavLink>
            </li>
            <li>
                <NavLink className="footer-link" to="/best-price-promise">
                    Best price promise
                </NavLink>
            </li>
            <li>
                <NavLink
                    to={
                        features?.siteWideBlogVersion === 2 ? '/blogs' : '/blog'
                    }
                >
                    Blog
                </NavLink>
            </li>
            <li>
                <NavLink className="footer-link" to="/contact-us">
                    Contact Us
                </NavLink>
            </li>
            <li>
                <NavLink className="footer-link" to="/cookies">
                    Cookie Policy
                </NavLink>
            </li>
            <li>
                <NavLink to={'/faqs'}>FAQs</NavLink>
            </li>
            <li>
                <NavLink className="footer-link" to="/financial-protection">
                    Financial protection
                </NavLink>
            </li>
            <li>
                <NavLink to={'/giving-back'}>Giving back</NavLink>
            </li>
            <li>
                <NavLink className="footer-link" to="/payment-options">
                    Payment option
                </NavLink>
            </li>
            <li>
                <NavLink className="footer-link" to="/privacy-policy">
                    Privacy Policy
                </NavLink>
            </li>
            <li>
                <NavLink
                    className="footer-link"
                    to="/agency-terms-and-conditions"
                >
                    Terms and Conditions
                </NavLink>
            </li>
            <li>
                <NavLink className="footer-link" to="/tailor-made">
                    {features?.siteWideTailorMadeRename ?? false
                        ? 'Private Tour'
                        : 'Tailor Made'}{' '}
                    Holidays
                </NavLink>
            </li>
            <li>
                <NavLink className="footer-link" to="/terms-of-use">
                    Terms of Use
                </NavLink>
            </li>
            <li>
                <NavLink className="footer-link" to="/trade/signup">
                    Trade Agent Registration
                </NavLink>
            </li>
            <li>
                <NavLink className="footer-link" to="/travel-updates">
                    Travel updates
                </NavLink>
            </li>
            <li>
                <NavLink className="footer-link" to="/book-with-confidence">
                    Trust
                </NavLink>
            </li>
            <li>
                <NavLink className="footer-link" to="/data-deletion">
                    Data Deletion
                </NavLink>
            </li>
            <li>
                <NavLink to={'/jobs'}>Work with Us</NavLink>
            </li>
        </ul>
    );
};

export default NavLinks;
