import classNames from 'classnames';
import { MenuLink } from '../../MenuLink';
import { mobileHeight } from '../../Header.v2.constants';
import styles from './SubMenu.module.scss';
import MenuItem from '../MenuItem/MenuItem';
import {
    FloatingFocusManager,
    FloatingNode,
    FloatingPortal,
} from '@floating-ui/react';
import { MenuProvider, useMenu } from '../../../../../contexts/MenuContext';
import { Icons } from '../../../../common/Icon/Icon';
import { Link } from 'react-router-dom';

export type SubMenuProps = React.HTMLAttributes<HTMLDivElement> & {
    title: string;
    url: string;
    icon?: keyof Icons;
    links: MenuLink[];
};

function SubMenu({
    title,
    url,
    icon,
    links,
    style = {},
    className,
}: SubMenuProps) {
    const {
        nodeId,
        context,
        refs,
        getReferenceProps,
        getFloatingProps,
        isMounted,
        setIsOpen,
        mountedStatus,
        onCloseTree,
    } = useMenu();

    if (nodeId === undefined || context === undefined) {
        return null;
    }

    if (links.length === 0) {
        return (
            <MenuItem
                className={classNames(styles.menuItem, className)}
                as={Link}
                to={url}
                icon={icon}
                onClick={handleClickLink}
            >
                {title}
            </MenuItem>
        );
    }

    return (
        <FloatingNode id={nodeId}>
            <MenuItem
                className={classNames(styles.menuItem, className)}
                icon={icon}
                ref={refs.setReference}
                hasChildMenu={true}
                {...getReferenceProps({
                    onClick: () => setIsOpen(true),
                })}
            >
                {title}
            </MenuItem>
            {links.length > 0 && isMounted && (
                <FloatingPortal>
                    <FloatingFocusManager context={context}>
                        <div
                            className={styles.subMenu}
                            ref={refs.setFloating}
                            style={
                                {
                                    '--mobile-height': `${mobileHeight}px`,
                                    ...style,
                                } as React.CSSProperties
                            }
                            {...getFloatingProps()}
                            data-status={mountedStatus}
                        >
                            <button
                                className={styles.backButton}
                                onClick={handleBack}
                            >
                                Back
                            </button>
                            {links.map((link, i) => {
                                return (
                                    <MenuProvider key={i}>
                                        <SubMenu
                                            title={link.title}
                                            url={link.url}
                                            links={link.subLinks}
                                        />
                                    </MenuProvider>
                                );
                            })}
                        </div>
                    </FloatingFocusManager>
                </FloatingPortal>
            )}
        </FloatingNode>
    );

    function handleBack() {
        setIsOpen(false);
    }

    function handleClickLink() {
        onCloseTree();
    }
}

export default SubMenu;
