import { Suggestion, SuggestionType } from '../data';
import { getSearchSuggestions } from '../apis/tourSearchApi';

import { groupBy } from '../utils/groupBy';

export interface Section {
    title: string;
    suggestions: Suggestion[];
}

export function getSuggestions(value: string): Promise<Section[]> {
    if (value.trim() !== '') {
        const sections: Section[] = [];

        return getSuggestionData().then(tourSuggestions => {
            const filteredSuggestions = [...tourSuggestions].filter(s =>
                s.name.toLowerCase().includes(value.trim().toLowerCase())
            );
            const popularSuggestions = filteredSuggestions.filter(
                s => s.isPopular
            );
            if (popularSuggestions.length > 0) {
                sections.push({
                    title: 'Popular',
                    suggestions: popularSuggestions,
                });
            }
            const otherSuggestions = filteredSuggestions.filter(
                s => !s.isPopular
            );

            groupBy(otherSuggestions, s => s.type).forEach(g => {
                sections.push({
                    title: g.key as unknown as string,
                    suggestions: g.values,
                });
            });
            return sections;
        });
    } else {
        return Promise.resolve([]);
    }
}

export function getSuggestionData(): Promise<Suggestion[]> {
    const suggestionsKey = 'searchSuggestions';
    const countriesKey = 'searchSuggestionsCountries';
    const regionsKey = 'searchSuggestionsRegions';

    const storedSuggestions = sessionStorage.getItem(suggestionsKey);
    const storedCountries = sessionStorage.getItem(countriesKey);
    const storedRegions = sessionStorage.getItem(regionsKey);

    if (storedSuggestions && storedCountries && storedRegions) {
        const suggestions: Suggestion[] = JSON.parse(storedSuggestions).map(
            (s: any) => ({
                name: s.name,
                type: s.type as unknown as SuggestionType,
                isPopular: s.isPopular,
            })
        );
        const countries: Suggestion[] = JSON.parse(storedCountries).map(
            (s: any) => ({
                name: s.name,
                type: s.type as unknown as SuggestionType,
                isPopular: s.isPopular,
            })
        );
        const regions: Suggestion[] = JSON.parse(storedRegions).map(
            (s: any) => ({
                name: s.name,
                type: s.type as unknown as SuggestionType,
                isPopular: s.isPopular,
            })
        );

        return Promise.resolve([...suggestions, ...countries, ...regions]);
    } else {
        return getSearchSuggestions().then(response => {
            const suggestions: Suggestion[] = response.searchSuggestions.map(
                s => ({
                    name: s.name,
                    type: s.type as unknown as SuggestionType,
                    isPopular: s.isPopular,
                })
            );
            const countries: Suggestion[] =
                response.searchSuggestionsCountries.map(s => ({
                    name: s.name,
                    type: s.type as unknown as SuggestionType,
                    isPopular: s.isPopular,
                }));
            const regions: Suggestion[] = response.searchSuggestionsRegions.map(
                s => ({
                    name: s.name,
                    type: s.type as unknown as SuggestionType,
                    isPopular: s.isPopular,
                })
            );

            sessionStorage.setItem(
                suggestionsKey,
                JSON.stringify(response.searchSuggestions)
            );
            sessionStorage.setItem(
                countriesKey,
                JSON.stringify(response.searchSuggestionsCountries)
            );
            sessionStorage.setItem(
                regionsKey,
                JSON.stringify(response.searchSuggestionsRegions)
            );

            return [...suggestions, ...countries, ...regions];
        });
    }
}
