import { ApiResponse, BasicApiResponse } from '@/endurance';
import {
    CreateBrochureRequestRequest,
    CreateBrochureRequestResult,
    CreateUserEnquiryRequest,
    CreateUserEnquiryResult,
    CreateUserInterestRequest,
    CreateWhoIsGoingRequest,
    CreateWhoIsGoingResult,
    FlightsEnquiryRequest as EnduranceFlightsEnquiryRequest,
    TourFlightsEnquiryRequest as EnduranceTourFlightsEnquiryRequest,
    TradeFlightEnquiryRequest as EnduranceTradeFlightEnquiryRequest,
} from '@/endurance/users';
import {
    FlightEnquiryRequest,
    SendEnquiryRequest,
    WhoIsGoingRequest,
    TourFlightsEnquiryRequest,
    TradeFlightEnquiryRequest,
} from '@/apis/userApi';

import { getTrackingCodes } from '../utils/tracking/trackingService';
import { post, postResponse } from './enduranceApi';
import { airportGroups } from '../data/airports';
import { UserFeedbackRequest } from '@/apis/userApi/UserFeedbackRequest';
import { CreateUserFeedbackRequest } from '@/endurance/users/CreateUserFeedbackRequest';
import mixpanelAnalytics from '../utils/analytics/providers/mixpanelAnalytics';
import { TourFlightsEnquiryResult } from '@/endurance/users/TourFlightsEnquiryResult';
import { MailingListSignupRequest } from '@/endurance/users/MailingListSignupRequest';

const userRequestBrochureUrl = '/users/BrochureRequests';
const userWhoIsGoingUrl = '/users/whoisgoingrequests';
const userInterestUrl = '/users/interests';
const userFlightEnquiryUrl = '/users/enquiries/flights';
const userTourFlightsEnquiryUrl = '/users/enquiries/tourflights';
const userEnquiryUrl = '/users/enquiries';
const userFeedbackurl = '/users/enquiries/feedback';
const tradeFlightEnquiryUrl = '/users/enquiries/trade/flights';
const mailingListSignupUrl = '/users/interests/mailingList/signup';

export function requestBrochure(
    firstName: string,
    surname: string,
    email: string,
    tourId: string,
    currencyCode: string
) {
    if (email) {
        const mixpanel = new mixpanelAnalytics();
        mixpanel.loginUser(email, false);
    }

    return postResponse<
        CreateBrochureRequestRequest,
        BasicApiResponse<CreateBrochureRequestResult>
    >(userRequestBrochureUrl, {
        name: firstName,
        surname,
        email,
        tourId,
        tourAssetType: 'Brochure',
        currencyCode,
        trackingCodes: getTrackingCodes(),
    });
}

export function sendWhoIsGoingRequest({
    firstName,
    lastName,
    email,
    message,
    departureId,
    currencyCode,
}: WhoIsGoingRequest) {
    const request: CreateWhoIsGoingRequest = {
        firstName,
        lastName,
        email,
        message,
        departureId,
        currencyCode,
        trackingCodes: getTrackingCodes(),
    };

    if (email) {
        const mixpanel = new mixpanelAnalytics();
        mixpanel.loginUser(email, false);
    }

    return postResponse<
        CreateWhoIsGoingRequest,
        BasicApiResponse<CreateWhoIsGoingResult>
    >(userWhoIsGoingUrl, request);
}

export function logInterest(
    tourId: string,
    title: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    page: string,
    pageUrl: string,
    currencyCode: string
) {
    const payload: CreateUserInterestRequest = {
        title,
        firstName,
        lastName,
        email,
        phone,
        tourId,
        page,
        pageUrl,
        currencyCode,
        trackingCodes: getTrackingCodes(),
    };

    if (email) {
        const mixpanel = new mixpanelAnalytics();
        mixpanel.loginUser(email, false);
    }

    return postResponse<CreateUserInterestRequest, ApiResponse>(
        userInterestUrl,
        payload
    );
}

export function mailingListSignup(
    firstName: string,
    lastName: string,
    email: string
) {
    const payload: MailingListSignupRequest = {
        firstName,
        lastName,
        email,
    };

    if (email) {
        const mixpanel = new mixpanelAnalytics();
        mixpanel.loginUser(email, false);
    }

    return postResponse<MailingListSignupRequest, ApiResponse>(
        mailingListSignupUrl,
        payload
    );
}

export function sendFlightEnquiry({
    firstName,
    lastName,
    email,
    phone,
    travelPlans,
    duration,
    destinations,
    adults,
    children,
    infants,
    budget,
    departureAirport,
    departureAirportGroup,
    dates,
    currencyCode,
}: FlightEnquiryRequest) {
    const request: EnduranceFlightsEnquiryRequest = {
        firstName,
        lastName,
        email,
        phone,
        travelPlans,
        duration,
        destinations,
        adults: Number.parseInt(adults) || 0,
        children: Number.parseInt(children) || 0,
        infants: Number.parseInt(infants) || 0,
        budget,
        departureAirport: departureAirport ? departureAirport.code : null,
        departureAirportGroup: departureAirportGroup
            ? departureAirportGroup.code
            : null,
        dates,
        currencyCode,
        trackingCodes: getTrackingCodes(),
    };

    if (email) {
        const mixpanel = new mixpanelAnalytics();
        mixpanel.loginUser(email, false);
    }

    return postResponse<
        EnduranceFlightsEnquiryRequest,
        BasicApiResponse<CreateUserEnquiryResult>
    >(userFlightEnquiryUrl, request);
}

export function sendTourFlightEnquiry({
    firstName,
    lastName,
    email,
    phoneNumber,
    adultCount,
    childCount,
    infantCount,
    departureAirportIATA,
    tourId,
    departureId,
    otherDetails,
    currencyCode,
}: TourFlightsEnquiryRequest) {
    const request: EnduranceTourFlightsEnquiryRequest = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phoneNumber,
        adultCount: adultCount,
        childCount: childCount,
        infantCount: infantCount,
        departureAirportIATA: departureAirportIATA.replace('#', ''),
        tourId: tourId,
        departureId: departureId,
        otherDetails: otherDetails,
        currencyCode,
        trackingCodes: getTrackingCodes(),
    };

    if (email) {
        const mixpanel = new mixpanelAnalytics();
        mixpanel.loginUser(email, false);
    }

    return postResponse<
        EnduranceTourFlightsEnquiryRequest,
        BasicApiResponse<TourFlightsEnquiryResult>
    >(userTourFlightsEnquiryUrl, request);
}

export function sendTradeTourFlightEnquiry({
    adultCount,
    childCount,
    infantCount,
    departureDate,
    returnDate,
    departureAirportCode,
    tourId,
    departureId,
    otherDetails,
    balanceDueDate,
    accessToken,
}: TradeFlightEnquiryRequest) {
    const request: EnduranceTradeFlightEnquiryRequest = {
        adultCount: adultCount,
        childCount: childCount,
        infantCount: infantCount,
        departureAirportCode: departureAirportCode.replace('#', ''),
        tourId: tourId,
        departureId: departureId,
        otherDetails: otherDetails,
        departureAirportName: '',
        departureDate: departureDate,
        returnDate: returnDate,
        balanceDueDate: balanceDueDate,
    };

    return post<EnduranceTradeFlightEnquiryRequest, ApiResponse>(
        tradeFlightEnquiryUrl,
        request,
        undefined,
        accessToken
    );
}

export function sendEnquiry({
    enquiryType,
    title,
    firstName,
    lastName,
    email,
    phone,
    tourId,
    contactPreference,
    travelPlans,
    isSubscribed,
    comments,
    destination,
    activity,
    budgetPerPerson,
    duration,
    tourDepartureDate,
    tourReturnDate,
    adults,
    children,
    childrensAges,
    infants,
    interestedInFlights,
    departureAirportCode,
    flightDepartureDate,
    flightReturnDate,
    passengers,
    flightClass,
    dates,
    travelType,
    requestVideoChat,
    currencyCode,
    tourDepartureId,
}: SendEnquiryRequest) {
    const request: CreateUserEnquiryRequest = {
        enquiryType,
        title,
        firstName,
        lastName,
        email,
        phone,
        tourId,
        contactPreference,
        travelPlans,
        isSubscribed: isSubscribed ?? true,
        destination,
        comments,
        activity,
        budgetPerPerson,
        duration,
        tourDepartureDate,
        tourReturnDate,
        adults:
            typeof adults === 'string' ? Number.parseInt(adults) : adults ?? 0,
        children:
            typeof children === 'string'
                ? Number.parseInt(children)
                : children ?? 0,
        infants:
            typeof infants === 'string'
                ? Number.parseInt(infants)
                : infants ?? 0,
        childrensAges,
        interestedInFlights: interestedInFlights ?? false,
        departureAirportIataCode: getAirportIataByCode(departureAirportCode),
        departureAirportGroup: getAirportGroupByCode(departureAirportCode),
        flightDepartureDate,
        flightReturnDate,
        passengers:
            typeof passengers === 'string'
                ? Number.parseInt(passengers)
                : passengers ?? 0,
        flightClass,
        dates,
        travelType,
        requestVideoChat: requestVideoChat ?? false,
        currencyCode,
        trackingCodes: getTrackingCodes(),
        tourDepartureId,
    };

    if (email) {
        const mixpanel = new mixpanelAnalytics();
        mixpanel.loginUser(email, false);
    }

    return postResponse<
        CreateUserEnquiryRequest,
        BasicApiResponse<CreateUserEnquiryResult>
    >(userEnquiryUrl, request);
}

export function sendUserFeedback({
    message,
    helpful,
    url,
}: UserFeedbackRequest) {
    const request: CreateUserFeedbackRequest = {
        message,
        helpful,
        url,
    };

    return post<CreateUserFeedbackRequest, ApiResponse>(
        userFeedbackurl,
        request
    );
}

function getAirportIataByCode(code?: string) {
    if (code && !code.startsWith('#')) {
        return code;
    } else {
        return '';
    }
}

function getAirportGroupByCode(code?: string) {
    if (code && code.startsWith('#')) {
        const airportGroup = airportGroups.filter(group => group.code === code);
        if (airportGroup && airportGroup.length > 0) {
            return airportGroup[0].name;
        } else {
            return '';
        }
    } else {
        return '';
    }
}

const userApi = {
    requestBrochure,
    sendWhoIsGoingRequest,
    logInterest,
    sendFlightEnquiry,
    sendEnquiry,
    sendUserFeedback,
    mailingListSignup,
};

export default userApi;
