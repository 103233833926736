import { ChangeEvent, FocusEvent, InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import searchLogo from '../../../assets/images/icons/search.svg';
import styles from './TextInput.module.scss';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
    value: string;
    name?: string;
    isRequired?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    type?: 'text' | 'email' | 'password' | 'search' | 'tel' | 'number';
    icon?: 'search';
    placeholder?: string;
    inputRef?: React.RefObject<HTMLInputElement>;
    className?: string;
}

function TextInput({
    value,
    name,
    isRequired = false,
    onChange,
    onBlur,
    onFocus,
    onKeyUp,
    type = 'text',
    icon,
    placeholder,
    inputRef,
    className,
    ...props
}: TextInputProps) {
    return (
        <input
            className={classNames(
                styles.textInput,
                { [styles.hasIcon]: !!icon },
                className
            )}
            style={{
                ...(icon === 'search'
                    ? { backgroundImage: `url(${searchLogo})` }
                    : {}),
            }}
            type={type}
            name={name}
            required={isRequired}
            id={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            ref={inputRef}
            {...props}
        />
    );
}

export default TextInput;
