import {
    useFloating,
    FloatingPortal,
    FloatingOverlay,
    FloatingFocusManager,
    useDismiss,
    useClick,
    useRole,
    useInteractions,
} from '@floating-ui/react';
import classNames from 'classnames';
import Icon from '../../common/Icon/Icon';
import useBodyClass from '../../../hooks/useBodyClass';
import useCloseOnBack from './useCloseOnBack';
import styles from './Modal.module.scss';

const defaultPortalId = 'popup-portal';

export interface ModalProps {
    isOpen: boolean;
    children: React.ReactNode;
    onIsOpenChange: (isOpen: boolean) => void;
    modalKey: string;
    reference?: Element | null;
    portalId?: string;
    closeOnBack?: boolean;
    hideWidgets?: boolean;
    className?: string;
}

function Modal({
    isOpen,
    onIsOpenChange,
    modalKey,
    children,
    reference,
    portalId = defaultPortalId,
    closeOnBack = false,
    hideWidgets = false,
    className,
}: ModalProps) {
    const { refs, context } = useFloating({
        open: isOpen,
        onOpenChange: onIsOpenChange,
        elements: {
            reference: reference,
        },
    });
    useCloseOnBack(closeOnBack, context.open, modalKey, () =>
        onIsOpenChange(false)
    );
    useBodyClass(styles.hideWidgets, context.open && hideWidgets);
    const click = useClick(context, {});
    const dismiss = useDismiss(context, {
        outsidePressEvent: 'mousedown',
    });
    const role = useRole(context);
    const { getFloatingProps } = useInteractions([click, dismiss, role]);

    return (
        <FloatingPortal id={portalId}>
            {context.open && (
                <>
                    <FloatingOverlay
                        className={styles.overlay}
                        lockScroll={true}
                    />
                    <FloatingFocusManager
                        context={context}
                        initialFocus={refs.floating}
                    >
                        <div
                            className={classNames(styles.modal, className)}
                            ref={refs.setFloating}
                            {...getFloatingProps()}
                        >
                            <button
                                className={classNames(
                                    styles.closeButton,
                                    'modal-closeButton'
                                )}
                                onClick={() => onIsOpenChange(false)}
                            >
                                <Icon icon={'close'} height={30} width={30} />
                            </button>
                            {children}
                        </div>
                    </FloatingFocusManager>
                </>
            )}
        </FloatingPortal>
    );
}

export default Modal;
