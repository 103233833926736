import {
    AnchorHTMLAttributes,
    ButtonHTMLAttributes,
    ElementType,
    ForwardedRef,
    forwardRef,
    ReactNode,
    RefAttributes,
    SyntheticEvent,
} from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classNames from 'classnames';
import Icon, { Icons } from '../../../../common/Icon/Icon';
import { useMenu } from '../../../../../contexts/MenuContext';
import styles from './MenuItem.module.scss';

export type MenuItemProps =
    | HtmlButtonProps
    | HtmlAnchorProps
    | ReactRouterDomLinkProps;

const MenuItem = forwardRef(function (
    {
        as,
        icon,
        children,
        onClick,
        hasChildMenu = false,
        className,
        ...props
    }: MenuItemProps,
    ref: ForwardedRef<HTMLElement>
) {
    const { onCloseTree } = useMenu();
    const Component = (as ?? 'button') as ElementType;
    return (
        <Component
            ref={ref}
            className={classNames(styles.menuItem, className)}
            onClick={handleClick}
            {...props}
        >
            {icon && <Icon className={styles.icon} icon={icon} />}
            <span className={styles.children}>{children}</span>
            {hasChildMenu && <Icon className={styles.chevron} icon="chevron" />}
        </Component>
    );

    function handleClick(e: SyntheticEvent) {
        onClick?.(e);
        if (!hasChildMenu) {
            onCloseTree();
        }
    }
});

interface MenuItemBaseProps<C extends 'button' | 'a' | typeof Link> {
    icon?: keyof Icons;
    children: ReactNode;
    hasChildMenu?: boolean;
    onClick?: (e: SyntheticEvent) => void;
    as?: C;
}

export type HtmlButtonProps = MenuItemBaseProps<'button'> &
    RefAttributes<HTMLButtonElement> &
    ButtonHTMLAttributes<HTMLButtonElement> & {
        url: string;
    };
export type HtmlAnchorProps = MenuItemBaseProps<'a'> &
    RefAttributes<HTMLAnchorElement> &
    AnchorHTMLAttributes<HTMLAnchorElement>;
export type ReactRouterDomLinkProps = MenuItemBaseProps<typeof Link> &
    LinkProps;

export default MenuItem;
