import { TourSearchParams } from '@/core/tourSearch';
import { format, parse, parseISO } from 'date-fns';
import * as queryString from 'qs';
import searchHelper from '../helpers/searchHelper';
import { TourSearchRequest } from '@/endurance/tourSearch';

export function getSearchParamsFromQuery(
    queryStringParams: string
): TourSearchParams {
    const parsedParams = queryString.parse(queryStringParams, {
        ignoreQueryPrefix: true,
    });

    const attributes = parsedParams.attributes
        ? (parsedParams.attributes as string).split(',')
        : null;

    const params: TourSearchParams = {
        text: parsedParams.text ? (parsedParams.text as string) : '',
        minAge: parsedParams.minAge
            ? parseInt(parsedParams.minAge as string)
            : null,
        maxAge: parsedParams.maxAge
            ? parseInt(parsedParams.maxAge as string)
            : null,
        minPrice: parsedParams.minPrice
            ? parseInt(parsedParams.minPrice as string)
            : null,
        maxPrice: parsedParams.maxPrice
            ? parseInt(parsedParams.maxPrice as string)
            : null,
        minDuration: parsedParams.minDuration
            ? parseInt(parsedParams.minDuration as string)
            : null,
        maxDuration: parsedParams.maxDuration
            ? parseInt(parsedParams.maxDuration as string)
            : null,
        startDate: parsedParams.startDate
            ? format(
                  parse(
                      parsedParams.startDate as string,
                      'ddMMMyyyy',
                      new Date()
                  ),
                  'yyyy-MM-dd'
              )
            : null,
        startDateFlexibility: parsedParams.startDateFlexibility
            ? parseInt(parsedParams.startDateFlexibility as string)
            : null,
        yearMonth: parsedParams.yearMonth
            ? parseInt(parsedParams.yearMonth as string)
            : null,
        attributes: attributes ?? [],
        countries: parsedParams.countries
            ? (parsedParams.countries as string).split(',')
            : [],
        regions: parsedParams.regions
            ? (parsedParams.regions as string).split(',')
            : [],
        physicalRatings: parsedParams.physicalRatings
            ? (parsedParams.physicalRatings as string)
                  .split(',')
                  .map(r => parseInt(r))
            : [],
        familyOnly: parsedParams.familyOnly === 'true',
        isAnywhere: parsedParams.isAnywhere === 'true',
        tourType: parseTourType(parsedParams.tourType as string | undefined),
        sortType: parseSortType(parsedParams.sortType as string),
        source: parsedParams.source ? (parsedParams.source as string) : null,
        hasPrivateRoom: parsedParams.hasPrivateRoom === 'true',
        pageNumber: parsedParams.pageNumber
            ? parseInt(parsedParams.pageNumber as string)
            : 1,
        loadResultsBeforePageNumber:
            (parsedParams.pageNumber
                ? parseInt(parsedParams.pageNumber as string)
                : 1) > 1,
        guideStyles: parsedParams.guideStyles
            ? (parsedParams.guideStyles as string).split(',')
            : [],
        searchRegions: parsedParams.searchRegions
            ? (parsedParams.searchRegions as string).split(',')
            : [],
    };

    return params;
}

function parseSortType(
    sortType: string
):
    | 'Recommended'
    | 'DiscountDesc'
    | 'DiscountPercentageDesc'
    | 'PriceAsc'
    | 'PriceDesc'
    | 'PricePerDayAsc'
    | 'PricePerDayDesc'
    | 'AlphabeticallyAsc'
    | 'AlphabeticallyDesc'
    | null {
    switch (sortType) {
        case 'Recommended':
        case 'DiscountDesc':
        case 'DiscountPercentageDesc':
        case 'PriceAsc':
        case 'PriceDesc':
        case 'PricePerDayAsc':
        case 'PricePerDayDesc':
        case 'AlphabeticallyAsc':
        case 'AlphabeticallyDesc':
            return sortType;

        default:
            return null;
    }
}

export const getQueryFromSearchParams = (
    searchParams: Partial<TourSearchParams>
) => {
    const params = {
        text: searchParams.text,
        minAge: searchParams.minAge,
        maxAge: searchParams.maxAge,
        minPrice: searchParams.minPrice,
        maxPrice: searchParams.maxPrice,
        minDuration: searchParams.minDuration,
        maxDuration: searchParams.maxDuration,
        startDate: searchParams.startDate
            ? format(
                  parse(searchParams.startDate, 'yyyy-MM-dd', new Date()),
                  'ddMMMyyyy'
              )
            : null,
        startDateFlexibility: searchParams.startDateFlexibility,
        yearMonth: searchParams.yearMonth,
        attributes: searchParams.attributes
            ? searchParams.attributes.join(',')
            : null,
        countries: searchParams.countries
            ? searchParams.countries.join(',')
            : null,
        regions: searchParams.regions ? searchParams.regions.join(',') : null,
        physicalRatings: searchParams.physicalRatings
            ? searchParams.physicalRatings.join(',')
            : null,
        familyOnly: searchParams.familyOnly ? searchParams.familyOnly : null,
        hasPrivateRoom: searchParams.hasPrivateRoom
            ? searchParams.hasPrivateRoom
            : null,
        isAnywhere: searchParams.isAnywhere ? searchParams.isAnywhere : null,
        sortType: searchParams.sortType ? searchParams.sortType : null,
        source: searchParams.source ? searchParams.source : null,
        pageNumber: searchParams.pageNumber ? searchParams.pageNumber : null,
        tourType: searchParams.tourType ? searchParams.tourType : null,
        guideStyles: searchParams.guideStyles
            ? searchParams.guideStyles.join(',')
            : null,
    };

    const filteredParams = searchHelper.removeUnusedEntries(params);

    return queryString.stringify(filteredParams, {
        encode: true,
        arrayFormat: 'comma',
    });
};

export function getSearchRequestFromSearchParams(
    searchParams: TourSearchParams,
    sessionId: string,
    page: number,
    pageSize: number,
    currencyCode: string,
    shouldLog: boolean
): TourSearchRequest {
    const request: TourSearchRequest = {
        text: searchParams.isAnywhere ? '' : searchParams.text,
        minAge: searchParams.minAge,
        maxAge: searchParams.maxAge,
        minPrice: searchParams.minPrice,
        maxPrice: searchParams.maxPrice,
        minDuration: searchParams.minDuration,
        maxDuration: searchParams.maxDuration,
        startDate: searchParams.startDate
            ? format(parseISO(searchParams.startDate), 'ddMMMyyyy')
            : null,
        startDateFlexibility: searchParams.startDateFlexibility,
        yearMonth: searchParams.yearMonth,
        attributes: searchParams.attributes
            ? searchParams.attributes.join(',')
            : null,
        attributeGroups: null,
        regions: searchParams.regions ? searchParams.regions.join(',') : null,
        countries: searchParams.countries
            ? searchParams.countries.join(',')
            : null,
        physicalRatings: searchParams.physicalRatings
            ? searchParams.physicalRatings.join(',')
            : null,
        familyOnly: searchParams.familyOnly,
        hasPrivateRoom: searchParams.hasPrivateRoom,
        page: searchParams.loadResultsBeforePageNumber
            ? 1
            : searchParams.pageNumber || page,
        pageSize: searchParams.loadResultsBeforePageNumber
            ? pageSize * page
            : pageSize,
        sortType: searchParams.sortType || null,
        currencyCode: currencyCode,
        shouldLog: shouldLog,
        session: sessionId,
        tourType: searchParams.tourType || null,
        guideStyles: searchParams.guideStyles
            ? searchParams.guideStyles.join(',')
            : null,
        searchRegions: searchParams.searchRegions
            ? searchParams.searchRegions.join(',')
            : null,
    };

    return request;
}

export const setSearchSource = (query: string, source: string) => {
    const parsedParams = queryString.parse(query, {
        ignoreQueryPrefix: true,
    });

    parsedParams.source = source;

    return queryString.stringify(parsedParams);
};

function parseTourType(
    source: string | null | undefined
): 'Group' | 'Private' | null {
    switch (source?.toLowerCase()) {
        case 'group':
            return 'Group';
        case 'private':
            return 'Private';
        default:
            return null;
    }
}
