import {
    ChangeEvent,
    SyntheticEvent,
    useEffect,
    useRef,
    useState,
} from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import {
    FloatingFocusManager,
    FloatingOverlay,
    FloatingPortal,
} from '@floating-ui/react';
import Icon from '../../../common/Icon/Icon';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import SearchOption from './SearchOption/SearchOption';
import {
    getSuggestionData,
    getSuggestions,
    Section,
} from '../../../../services/searchSuggestionService';
import { Suggestion, SuggestionType } from '../../../../data';
import { getQueryFromSearchParams } from '../../../../services/tourSearchService';
import { sendEventV2 } from '../../../../utils/analytics/analyticsService';
import {
    Action,
    Area,
    InteractionItem,
    Page,
} from '../../../../utils/analytics/enums';
import { useMenu } from '../../../../contexts/MenuContext';
import styles from './MobileSearchMenu.module.scss';

export interface MobileSearchMenuProps {
    className?: string;
}

export interface TourSearchFieldValue {
    text: string;
    isAnywhere: boolean;
}

function MobileSearchMenu({ className }: MobileSearchMenuProps) {
    const windowWidth = useWindowWidth();
    const history = useHistory();
    const textInputRef = useRef<HTMLInputElement>(null);
    const [values, setValues] = useState<TourSearchFieldValue>(initialValue);
    const {
        nodeId,
        refs,
        isMounted,
        mountedStatus,
        context,
        setIsOpen,
        getFloatingProps,
    } = useMenu();

    const [popularSuggestions, setPopularSuggestions] = useState<Section[]>([]);

    useEffect(() => {
        getSuggestionData().then(tourSuggestions => {
            setPopularSuggestions([
                {
                    title: 'Popular',
                    suggestions: tourSuggestions.filter(s => s.isPopular),
                },
            ]);
        });
    }, []);
    const [sections, setSections] = useState<Section[]>(popularSuggestions);

    useEffect(() => {
        if (windowWidth > 1024) {
            setIsOpen(false);
        }
    }, [windowWidth, setIsOpen]);

    if (nodeId === undefined || context === undefined || !isMounted) {
        return null;
    }

    return (
        <FloatingPortal id="mobile-menu">
            {isMounted && (
                <>
                    <FloatingOverlay
                        className={styles.overlay}
                        lockScroll={true}
                    />
                    <FloatingFocusManager
                        context={context}
                        initialFocus={textInputRef}
                    >
                        <div
                            className={classNames(
                                styles.mobileSearchMenu,
                                className
                            )}
                            ref={refs.setFloating}
                            data-status={mountedStatus}
                            {...getFloatingProps()}
                        >
                            <nav className={styles.header}>
                                <h2>Where to?</h2>
                                <button
                                    className={styles.closeButton}
                                    onClick={handleClose}
                                >
                                    <Icon icon="x" />
                                </button>
                            </nav>
                            <form
                                className={styles.form}
                                onSubmit={handleSearch}
                            >
                                <div className={styles.searchInput}>
                                    <input
                                        ref={textInputRef}
                                        type="search"
                                        id="text"
                                        name="text"
                                        value={values.text}
                                        onChange={handleTextChange}
                                        placeholder="Search"
                                        autoComplete="off"
                                        tabIndex={-1}
                                    />
                                    <label htmlFor="text">
                                        <Icon icon="search" />
                                    </label>
                                </div>
                            </form>
                            <div className={styles.results}>
                                {sections.map(({ title, suggestions }) => (
                                    <>
                                        <h3>{title}</h3>
                                        <ul>
                                            {suggestions.map(suggestion => (
                                                <SearchOption
                                                    suggestion={suggestion}
                                                    onClick={handleSelectOption}
                                                />
                                            ))}
                                        </ul>
                                    </>
                                ))}
                            </div>
                        </div>
                    </FloatingFocusManager>
                </>
            )}
        </FloatingPortal>
    );

    function handleSearch(e: SyntheticEvent) {
        e.preventDefault();
        redirectToSearch(values);

        sendEventV2(
            Page.header,
            Area.searchWidget,
            InteractionItem.searchBox,
            Action.submit,
            {
                searchText: values.isAnywhere ? 'Anywhere' : values.text,
                attributeData: null,
                dateTimeData: null,
            }
        );
    }

    function handleTextChange(e: ChangeEvent<HTMLInputElement>) {
        const newText = e.target.value;
        setValues({
            text: newText,
            isAnywhere: newText.trim() === '',
        });
        if (newText.trim() !== '') {
            getSuggestions(newText).then(sections => {
                setSections(sections);
            });
        } else {
            setSections(popularSuggestions);
        }
    }

    function handleSelectOption(suggestion: Suggestion) {
        const newValue = {
            text: suggestion.name,
            isAnywhere: suggestion.type === SuggestionType.Anywhere,
        };
        setValues(newValue);
        redirectToSearch(newValue);

        sendEventV2(
            Page.header,
            Area.searchWidget,
            InteractionItem.searchBox,
            Action.select,
            {
                searchText: newValue.isAnywhere ? 'Anywhere' : newValue.text,
                attributeData: null,
                dateTimeData: null,
            }
        );
    }

    function redirectToSearch(value: TourSearchFieldValue) {
        const searchParamsString = getQueryFromSearchParams({
            text: value.text,
            isAnywhere: value.isAnywhere,
            source: 'desktop-header',
        });

        history.push({
            pathname: '/search',
            search: `${searchParamsString}`,
        });

        resetForm();
        handleClose();
    }

    function resetForm() {
        setValues(initialValue);
        setSections(popularSuggestions);
    }

    function handleClose() {
        setIsOpen(false);
    }
}

const initialValue: TourSearchFieldValue = {
    text: '',
    isAnywhere: true,
};

export default MobileSearchMenu;
