import DesktopMenu from '../DesktopMenu/DesktopMenu';
import { DesktopMenuProvider } from '../DesktopMenu/DesktopMenuContext';
import { useDestinationLinks } from '../../useDestinationLinks';

export interface DestinationsMenuProps {
    className?: string;
}

function DestinationsMenu({ className }: DestinationsMenuProps) {
    const { isLoading, destinationLinks } = useDestinationLinks();

    if (isLoading) {
        return null;
    }

    return (
        <DesktopMenuProvider>
            <DesktopMenu className={className} links={destinationLinks}>
                Destinations
            </DesktopMenu>
        </DesktopMenuProvider>
    );
}

export default DestinationsMenu;
