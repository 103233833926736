import { forwardRef } from 'react';
import classNames from 'classnames';
import styles from './Icon.module.scss';
import { getIcon } from './iconHelper';
import icons from './iconHelper';

export type Icons = typeof icons;

export type IconProps = {
    icon: keyof Icons;
    height?: number;
    width?: number;
    style?: any;
    className?: string;
    color?: string;
} & React.SVGProps<SVGSVGElement>;

export const Icon = forwardRef(function (
    {
        icon,
        height = 30,
        width = 30,
        style = {},
        className = undefined,
        color,
        ...props
    }: IconProps,
    ref
) {
    const SvgIcon = getIcon(icon);
    if (!SvgIcon) {
        return null;
    }
    return (
        <SvgIcon
            ref={ref}
            height={height}
            width={width}
            className={classNames(styles.icon, className)}
            style={{
                ...(color ? { fill: `#${color}` } : {}),
                ...(color ? { stroke: `#${color}` } : {}),
                ...style,
            }}
            fill={color}
            {...props}
        />
    );
});

export default Icon;
