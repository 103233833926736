import { createContext, useContext, useEffect, useState } from 'react';
import {
    autoUpdate,
    FloatingContext,
    useClick,
    useDismiss,
    useFloating,
    useFloatingNodeId,
    useFloatingParentNodeId,
    useFloatingTree,
    useInteractions,
    useRole,
    useTransitionStatus,
} from '@floating-ui/react';

export interface MenuContextValue {
    nodeId: string | undefined;
    context: FloatingContext | undefined;
    getItemProps: (
        userProps?: React.HTMLProps<HTMLElement>
    ) => Record<string, unknown>;
    activeIndex: number | null;
    setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>;
    setHasFocusInside: React.Dispatch<React.SetStateAction<boolean>>;
    isMounted: boolean;
    mountedStatus: 'unmounted' | 'initial' | 'open' | 'close';
    setIsOpen: (isOpen: boolean) => void;
    refs: {
        setReference: (node: HTMLElement | null) => void;
        setFloating: (node: HTMLElement | null) => void;
    };
    getReferenceProps: (
        userProps?: React.HTMLProps<Element>
    ) => Record<string, unknown>;
    getFloatingProps: (
        userProps?: React.HTMLProps<Element>
    ) => Record<string, unknown>;
    hasFocusInside: boolean;
    onCloseTree: () => void;
}

const initialContextValue: MenuContextValue = {
    nodeId: undefined,
    context: undefined,
    getItemProps: () => ({}),
    activeIndex: null,
    setActiveIndex: () => {},
    setHasFocusInside: () => {},
    isMounted: false,
    mountedStatus: 'unmounted',
    setIsOpen: () => {},
    getReferenceProps: () => ({}),
    getFloatingProps: () => ({}),
    refs: {
        setReference: () => {},
        setFloating: () => {},
    },
    hasFocusInside: false,
    onCloseTree: () => {},
};

export const MenuContext = createContext<MenuContextValue>(initialContextValue);

export function useMenu() {
    const context = useContext(MenuContext);
    if (!context) {
        throw new Error('No Mobile Menu Context initialised.');
    }
    return context;
}

export interface MenuProviderProps {
    children: React.ReactNode;
    transitionDuration?: number;
}

export function MenuProvider({
    children,
    transitionDuration = 100,
}: MenuProviderProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [hasFocusInside, setHasFocusInside] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const parent = useMenu();
    const tree = useFloatingTree();
    const nodeId = useFloatingNodeId();
    const parentId = useFloatingParentNodeId();

    const isNested = parentId != null;

    const { refs, context } = useFloating<HTMLButtonElement>({
        nodeId,
        open: isOpen,
        onOpenChange: handleSetIsOpen,
        whileElementsMounted: autoUpdate,
        transform: false,
    });

    const click = useClick(context, {
        event: 'mousedown',
        toggle: !isNested,
        ignoreMouse: isNested,
    });
    const role = useRole(context, { role: 'menu' });
    const dismiss = useDismiss(context, { bubbles: true });

    const { getReferenceProps, getFloatingProps, getItemProps } =
        useInteractions([click, role, dismiss]);

    const { isMounted, status: mountedStatus } = useTransitionStatus(context, {
        duration: transitionDuration,
    });

    // Event emitter allows you to communicate across tree components.
    // This effect closes all menus when an item gets clicked anywhere
    // in the tree.
    useEffect(() => {
        if (!tree) return;

        function handleTreeClick() {
            setIsOpen(false);
        }

        function onSubMenuOpen(event: { nodeId: string; parentId: string }) {
            if (event.nodeId !== nodeId && event.parentId === parentId) {
                setIsOpen(false);
            }
        }

        tree.events.on('click', handleTreeClick);
        tree.events.on('menuopen', onSubMenuOpen);

        return () => {
            tree.events.off('click', handleTreeClick);
            tree.events.off('menuopen', onSubMenuOpen);
        };
    }, [tree, nodeId, parentId, parent]);

    useEffect(() => {
        if (isOpen && tree) {
            tree.events.emit('menuopen', { parentId, nodeId });
        }
    }, [tree, isOpen, nodeId, parentId]);

    return (
        <MenuContext.Provider
            value={{
                nodeId,
                context,
                activeIndex,
                setActiveIndex,
                getItemProps,
                setHasFocusInside,
                isMounted,
                mountedStatus,
                setIsOpen,
                refs,
                getReferenceProps,
                getFloatingProps,
                hasFocusInside,
                onCloseTree: handleCloseTree,
            }}
        >
            {children}
        </MenuContext.Provider>
    );

    function handleSetIsOpen(isOpen: boolean) {
        setIsOpen(isOpen);
    }

    function handleCloseTree() {
        setIsOpen(false);
        parent.onCloseTree();
    }
}
