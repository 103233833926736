import React from 'react';
import classNames from 'classnames';
import ScrollToTopOnMount from '../ScrollToTopOnMount/ScrollToTopOnMount';
import LoadingLogo from '../common/LoadingLogo/LoadingLogo';
import LoadingText from '../common/LoadingText/LoadingText';
import styles from './PageLoading.module.scss';

export type PageLoadingProps = {
    text?: string;
} & React.HTMLAttributes<HTMLDivElement>;

function PageLoading({ text, className, ...props }: PageLoadingProps) {
    return (
        <div className={classNames(styles.pageLoading, className)} {...props}>
            <ScrollToTopOnMount />
            <LoadingLogo className={styles.spinner} />
            <LoadingText className={styles.loadingText} text={text} />
        </div>
    );
}

export default PageLoading;
