export const airports = [
    { iataCode: 'LCY', name: 'London City' },
    { iataCode: 'LHR', name: 'London Heathrow' },
    { iataCode: 'LGW', name: 'London Gatwick' },
    { iataCode: 'STN', name: 'London Stansted' },
    { iataCode: 'SEN', name: 'London Southend' },
    { iataCode: 'LTN', name: 'London Luton' },
    { iataCode: 'BHX', name: 'Birmingham' },
    { iataCode: 'BOH', name: 'Bournemouth' },
    { iataCode: 'BRS', name: 'Bristol' },
    { iataCode: 'EMA', name: 'East Midlands' },
    { iataCode: 'LPL', name: 'Liverpool' },
    { iataCode: 'MAN', name: 'Manchester' },
    { iataCode: 'SOU', name: 'Southampton' },
    { iataCode: 'NCL', name: 'Newcastle' },

    { iataCode: 'EDI', name: 'Edinburgh' },
    { iataCode: 'GLA', name: 'Glasgow International' },
    { iataCode: 'PIK', name: 'Glasgow Prestwick' },
    { iataCode: 'ABZ', name: 'Aberdeen' },
    { iataCode: 'INV', name: 'Inverness' },

    { iataCode: 'CWL', name: 'Cardiff' },
    { iataCode: 'SWS', name: 'Swansea' },

    { iataCode: 'BFS', name: 'Belfast International' },
    { iataCode: 'BHD', name: 'Belfast City' },
];

export const airportGroups = [
    { name: 'All London', code: '#LDN' },
    { name: 'All Scotland', code: '#SCO' },
    { name: 'All Wales', code: '#WAL' },
    { name: 'All Northern Ireland', code: '#NIR' },
    { name: 'All North West', code: '#NWE' },
    { name: 'All Midlands', code: '#MID' },
    { name: 'All South West', code: '#SWE' },
];
