import { takeLatest, all } from 'redux-saga/effects';
import * as types from '../actions/types';

import {
    confirmAccommodationPrice,
    requestAccommodation,
} from './accommodation.sagas';
import { requestAccommodationRooms } from './accommodationRooms.sagas';
import {
    populateAccommodationSearchParamsSaga,
    performAccommodationSearchSaga,
    incrementAccommodationSearchPageSaga,
    filterAccommodationSearchSaga,
    sortAccommodationSearchSaga,
    resetAccommodationFiltersSaga,
} from './accommodationSearch.sagas';
import {
    createBookingRequest,
    requestPaymentPlan,
    applyPromoCode,
    resetPromoCode,
} from './checkout.sagas';
import { loadAirportData } from './flight.sagas';
import { performPackagesSearchSaga } from './packagesSearch.sagas';
import { requestSiteDataSaga, requestLocalisationDataSaga } from './site.sagas';
import { requestTour, requestTourRoomDataSaga } from './tour.sagas';
import {
    resetTourFiltersSaga,
    populateTourSearchParamsSaga,
    performTourSearchSaga,
    incrementTourPageSaga,
    filterTourSearchSaga,
} from './tourSearch.sagas';
import {
    resetTourFlightSearchFiltersSaga,
    populateTourFlightSearchParamsSaga,
    performTourFlightSearchSaga,
    incrementTourFlightSearchPageSaga,
    filterTourFlightSearchSaga,
    sortTourFlightSearchSaga,
} from './tourFlightSearch.sagas';
import { requestActivities } from './lookups.sagas';
import { requestAgents } from './agents.sagas';

import {
    searchTourDeparturesSaga,
    getTourPackageSaga,
} from './tourPackages.sagas';
import { requestTourDepartures } from './tourDeparture.sagas';
import { requestTourDepartureFlightSaga } from './tourDepartureFlight.sagas';

function* mySaga() {
    yield all([
        takeLatest(types.REQUEST_TOUR, requestTour),
        takeLatest(types.REQUEST_TOUR_DEPARTURES, requestTourDepartures),
        takeLatest(types.TOUR_REQUEST_TOUR_ROOM_DATA, requestTourRoomDataSaga),
        takeLatest(
            types.REQUEST_TOUR_DEPARTURE_FLIGHT,
            requestTourDepartureFlightSaga
        ),
        takeLatest(types.CREATE_BOOKING_REQUEST, createBookingRequest),
        takeLatest(types.REQUEST_SITE_DATA, requestSiteDataSaga),
        takeLatest(
            types.REQUEST_LOCALISATION_DATA,
            requestLocalisationDataSaga
        ),

        // Tour Search
        takeLatest(types.RESET_FILTERS, resetTourFiltersSaga),
        takeLatest(types.POPULATE_SEARCH_PARAMS, populateTourSearchParamsSaga),
        takeLatest(types.PERFORM_SEARCH, performTourSearchSaga),
        takeLatest(types.FILTER_SEARCH, filterTourSearchSaga),
        takeLatest(types.SORT_SEARCH, performTourSearchSaga),
        takeLatest(types.INCREMENT_PAGE, incrementTourPageSaga),

        // Tour Flight Search
        takeLatest(
            types.RESET_TOUR_FLIGHT_SEARCH_FILTERS,
            resetTourFlightSearchFiltersSaga
        ),
        takeLatest(
            types.POPULATE_TOUR_FLIGHT_SEARCH_PARAMS,
            populateTourFlightSearchParamsSaga
        ),
        takeLatest(
            types.PERFORM_TOUR_FLIGHT_SEARCH,
            performTourFlightSearchSaga
        ),
        takeLatest(types.FILTER_TOUR_FLIGHT_SEARCH, filterTourFlightSearchSaga),
        takeLatest(types.SORT_TOUR_FLIGHT_SEARCH, sortTourFlightSearchSaga),
        takeLatest(
            types.INCREMENT_TOUR_FLIGHT_SEARCH_PAGE,
            incrementTourFlightSearchPageSaga
        ),

        // Checkout
        takeLatest(types.REQUEST_PAYMENT_PLAN, requestPaymentPlan),
        takeLatest(types.APPLY_PROMO_CODE, applyPromoCode),
        takeLatest(types.RESET_PROMO_CODE, resetPromoCode),

        // Flights
        takeLatest(types.FLIGHTS_AIRPORT_DATA_REQUEST, loadAirportData),

        // Accommodation
        takeLatest(types.ACCOM_REQUEST, requestAccommodation),

        // Accommodation Search
        takeLatest(
            types.ACCOM_POPULATE_SEARCH_PARAMS,
            populateAccommodationSearchParamsSaga
        ),
        takeLatest(types.ACCOM_SEARCH_REQUEST, performAccommodationSearchSaga),
        takeLatest(
            types.ACCOM_SEARCH_INCREMENT_REQUEST,
            incrementAccommodationSearchPageSaga
        ),
        takeLatest(types.ACCOM_SEARCH_FILTER, filterAccommodationSearchSaga),
        takeLatest(
            types.ACCOM_SEARCH_FILTER_RESET,
            resetAccommodationFiltersSaga
        ),
        takeLatest(types.ACCOM_SEARCH_SORT, sortAccommodationSearchSaga),

        // Accommodation Room Search
        takeLatest(types.ACCOM_ROOMS_REQUEST, requestAccommodationRooms),

        // Accommodation Checkout
        takeLatest(
            types.ACCOM_CONFIRM_PRICE_REQUEST,
            confirmAccommodationPrice
        ),
        // Packages Search
        takeLatest(types.PACKAGES_SEARCH_REQUEST, performPackagesSearchSaga),
        // Activities
        takeLatest(types.LOOKUPS_ACTIVITIES_REQUEST, requestActivities),
        // Agents
        takeLatest(types.FETCH_AGENTS_REQUEST, requestAgents),

        takeLatest(
            types.TOUR_DEPARTURES_SEARCH_REQUEST,
            searchTourDeparturesSaga
        ),
        takeLatest(types.TOUR_PACKAGES_GET_REQUEST, getTourPackageSaga),
    ]);
}

export default mySaga;
