import classNames from 'classnames';
import { Link } from 'react-router-dom';
import CircularIconButton from '../../../common/CircularIconButton/CircularIconButton';
import styles from './MyAccountButton.module.scss';
import { useLogin } from '../../../../contexts/LoginContext';

export interface MyAccountButtonProps {
    className?: string;
}

function MyAccountButton({ className }: MyAccountButtonProps) {
    const { isLoggedIn, isTrade } = useLogin();

    return (
        <CircularIconButton
            className={classNames(styles.myAccountButton, className)}
            as={Link}
            to={isTrade ? '/trade/dashboard' : '/myaccount'}
            icon={isTrade ? 'chartLine' : 'userSolid'}
            title={
                isTrade
                    ? 'Agent Dashboard'
                    : isLoggedIn
                      ? 'My Account'
                      : 'Sign in'
            }
            rel="noopener nofollow"
            onClick={handleClick}
            data-testid="my-account-header-link"
        />
    );

    function handleClick() {}
}

export default MyAccountButton;
